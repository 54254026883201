import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import loading from './loadingReducer'
import messages from './messagesReducer';
import minhaAgencia from './minhaAgenciaReducer';

const rootReducer = combineReducers({
    form: formReducer,

    loading,
    messages,
    minhaAgencia

});

export default rootReducer
