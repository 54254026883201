export const SHOW_MESSAGE = '@messagesReducer/SHOW_MESSAGE';
export const DISMISS_MESSAGE = '@messagesReducer/DISMISS_MESSAGE';

const INITIAL_STATE = { message: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return action.payload;
    case DISMISS_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};
