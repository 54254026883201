/* eslint-disable */
import React from "react";

const onlyUnique = (value, index, self) => self.indexOf(value) === index
export default valores => valores.filter(onlyUnique)

export const mascaraMoeda = (a) => {
  let n = '',
    h,
    j = 0,
    u,
    tamanho2 = 0,
    l,
    ajd2 = '';
  const e = '.'
  const r = ','

  for (u = a.length, h = 0; h < u && (a.charAt(h) === '0' || a.charAt(h) === r); h++) ;
  for (l = ''; h < u; h++) '0123456789'.indexOf(a.charAt(h)) !== -1 && (l += a.charAt(h));
  if (l += n,
  (u = l.length) === 0 && (a = ''),
  u === 1 && (a = `0${r}0${l}`),
  u === 2 && (a = `0${r}${l}`),
  u > 2) {
    for (ajd2 = '',
    j = 0,
    h = u - 3; h >= 0; h--) {
      j === 3 && (ajd2 += e,
      j = 0),
      ajd2 += l.charAt(h),
      j++;
    }
    for (a = '',
    tamanho2 = ajd2.length,
    h = tamanho2 - 1; h >= 0; h--) a += ajd2.charAt(h);
    a += r + l.substr(u - 2, u)
  }
  return a
}

export const renderField = ({
                       input,
                       label,
                       type,
                       css,
                       max,
                       readOnly,
                       meta: { touched, error }
                     }) => (
  <div>
    <label className='mt-2'>{label}</label>
    <div>
      <input {...input} type={type}
             className={css}
             maxLength={max}
             style={{ borderColor: touched && error ? 'red' : '' }}
             disabled={readOnly}
             readOnly={readOnly}/>
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);


export const mascaraNumero = numero => numero.replace(/\D/g, '')

export function groupArrayOfObjects (list, key) {
  return list.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}


export function formatarReal (valor) {
    if (valor) {
        return (Number(valor.replace(',', '.'))
            .toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            }))
    }
    return Number(0)
        .toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
        })
}
