import React, { Component } from 'react'

class PoliticaPrivacidade extends Component {
    render() {
        return (
            <div className='conteudo'>
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1>Política de privacidade</h1>
                                <div className="divider" />
                            </div>
                            <div className='card'>
                                <div className='card-body informacoes'>
                                    <p>Você pode ter acesso a algumas funcionalidades da Plataforma sem a necessidade de criar uma conta na Quero uma Agência. Se você quiser criar uma conta, a Quero uma Agência precisa tratar alguns dados pessoais seus, pois não é possível oferecer certas funcionalidades da Plataforma sem ter acesso a esses dados pessoais. Além disso, a Quero uma Agência pode coletar dados pessoais de visitantes da Plataforma para determinadas finalidades. Por isso, se você não concordar com esta Política de Privacidade, você deve cessar o seu acesso ou uso da Plataforma.</p>
                                    <p>Esta Política pode ser modificada, substituída ou removida a qualquer momento, sem aviso prévio. Se você não concordar com uma nova versão desta Política, você deve cessar o uso da sua conta na Quero uma Agência. Esta Política e suas respectivas atualizações prevalecem sobre quaisquer propostas, contratos, entendimentos anteriores e acordos, verbais ou escritos, que possam existir entre você e a Quero uma Agência, especialmente versando sobre privacidade ou dados pessoais.</p>
                                    <p><b>VISÃO GERAL</b></p>
                                    <ol>
                                        <li>Esta Política de Privacidade estabelece as regras sobre (a) como e para quais finalidades seus dados pessoais podem ser tratados pela Quero uma Agência, (b) com quem esses dados podem ser compartilhados, e (c) como os Usuários podem gerenciar seus dados pessoais. Ela se aplica ao uso ou acesso à Plataforma mediante o site Quero uma Agência.com e seus subdomínios, bem como mediante outros sites, interfaces ou aplicativos nos quais a Quero uma Agência disponibilize seus recursos, inclusive nossos aplicativos de celular, tablet ou de outros dispositivos eletrônicos.</li>
                                        <li>Para facilitar a leitura desta Política, oferecemos um Glossário, que relaciona o significado das expressões iniciadas por letras maiúsculas nos Termos da Quero uma Agência, bem como nesta ou em outras Políticas da Quero uma Agência. Além disso, é importante você compreender o significado específico das três expressões a seguir, utilizadas no contexto da proteção à privacidade e grafadas nos Termos, nesta Política ou em outras Políticas da Quero uma Agência sem iniciais maiúsculas:</li>
                                    </ol>
                                    <p>“Dados pessoais”, no singular ou no plural, significa qualquer informação que identifique o Usuário ou que torne possível a sua identificação. Logo, por mais genérico que isso possa parecer, um dado pessoal pode ser qualquer informação que identifique você diretamente ou qualquer informação que funcione como uma peça no quebra-cabeça que leve à sua identificação.</p>
                                    <p>“Tratamento”, assim como o verbo “tratar”, significa qualquer operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação, controle, modificação, comunicação, transferência, difusão ou extração.</p>
                                    <p>“Serviços do QuApay”, a Quero uma Agência pode permitir que seus parceiros (como instituições financeiras, instituições de pagamento responsáveis pela emissão dos cartões sob licença das bandeiras, bandeiras e correspondentes bancários) ofereçam seus serviços de forma agregada como, por exemplo, a disponibilização do Cartão aos Usuários, o que será oferecido apenas aos usuários localizados no território brasileiro. Na hipótese do Usuário optar pelo Cartão, podem existir termos e condições desses parceiros que você deve cumprir. Esses parceiros são mencionados na Política do Cartão Quero uma Agência para o Brasil.</p>
                                    <ol>
                                        <li><b>QUAIS SÃO OS DADOS PESSOAIS TRATADOS PELA QUERO UMA AGÊNCIA</b></li>
                                    </ol>
                                    <p>Dados Pessoais. Os Dados Pessoais tratados pela Quero uma Agência podem variar a depender do tipo de interação do Usuário com a Plataforma. Esses dados pessoais podem ser coletados diretamente do Usuário, obtidos através de terceiros ou coletados automaticamente. Os dados pessoais podem envolver: (a) informações cadastrais, como e-mail, nome completo, endereço, telefone, celular, CPF, RG, CNH, foto de perfil e passaporte; e (b) informações bancárias, como nome da instituição financeira na qual o Usuário possui conta bancária, número da conta, tipo de conta, agência, IBAN e dados do cartão de crédito, incluindo nome do titular, validade e código CVV.</p>
                                    <p>1.1. Dados Pessoais coletados diretamente do Usuário. A Quero uma Agência pode coletar dados pessoais diretamente de você, relativos a informações cadastrais e informações bancárias.</p>
                                    <p>1.1.1. Interação “offline”. A Quero uma Agência pode coletar dados pessoais quando você interage com a Quero uma Agência por outros meios que não seja o acesso à Plataforma, como, por exemplo, quando você participa de um evento organizado pela Quero uma Agência ou um evento organizado por um terceiro cuja atividade esteja contratualmente vinculada à Quero uma Agência.</p>
                                    <p>1.1.2. Campos opcionais. A Plataforma disponibiliza certos campos de preenchimento opcional. Esses campos estão destacados como opcionais e têm a finalidade de melhorar a personalização do conteúdo disponibilizado ao Usuário.</p>
                                    <p>1.1.3. Dados públicos. A Quero uma Agência considera alguns dados pessoais como públicos, porque podem ser vistos por qualquer pessoa, dentro e fora da Plataforma, inclusive se essa pessoa não tiver uma conta na Plataforma. São considerados dados públicos as informações que você inserir nos campos destacados com o aviso “dados públicos”. Você, outras pessoas que usam a Plataforma e a própria Quero uma Agência podem fornecer acesso ou enviar dados públicos para qualquer pessoa dentro e fora da Plataforma. É possível também visualizar, acessar, compartilhar novamente ou baixar dados públicos por meio de ferramentas de terceiros, como mecanismos de pesquisa, APIs e mídia offline, ou por meio de aplicativos, sites e outros Serviços de Terceiros.</p>
                                    <p>1.2. Dados pessoais obtidos de terceiros. A Quero uma Agência pode ter acesso a determinados dados pessoais por intermédio de terceiros, sejam eles informações cadastrais ou bancárias. Isso acontece, por exemplo, quando, em razão de cumprimento de obrigação legal ou regulatória, a Quero uma Agência precisa consultar bancos de dados mantidos por terceiros que viabilizem a análise de restrições creditícias, análises antifraude e demais análises de gestão de risco integrados à Plataforma.</p>
                                    <p>1.2.1. A Quero uma Agência também coleta informações cadastrais por intermédio de terceiros quando você vincula, se conecta ou faz login na Plataforma por um Serviço de Terceiro (por exemplo, Facebook).</p>
                                    <p>1.2.2. A Quero uma Agência pode também ter acesso a informações cadastrais por meio de terceiros para inscrição em eventos, pesquisas, campanhas publicitárias e demais atividades organizadas por esses terceiros, cuja atividade esteja contratualmente vinculada à Quero uma Agência.</p>
                                    <p>1.3. Dados Pessoais coletados automaticamente ao acessar a Plataforma. Dados pessoais são coletados automaticamente quando você acessa a Plataforma, mesmo se você não tiver conta na Plataforma. Essas informações podem incluir as exemplificadas abaixo.</p>
                                    <p>1.3.1. Informações de transação de pagamento: instrumento de pagamento usado; data e hora da transação; valor do pagamento; data de vencimento do instrumento de pagamento; código postal de cobrança; informações do IBAN, endereço e outros detalhes relacionados à transação de pagamento.</p>
                                    <p>1.3.2. Informações de acesso ou uso: páginas e conteúdo que você visualiza; buscas por anúncios; frequência de utilização da Plataforma; informação sobre o navegador; dados de conexão; histórico de compras e histórico de navegação.</p>
                                    <p>1.3.3. Dados de log e informações do dispositivo: detalhes sobre como você acessou e usou a Plataforma (incluindo links acessados); endereço de IP; datas e horários de acesso; informações de hardware e software; informações do dispositivo; informações de eventos do dispositivo; identificadores exclusivos (por exemplo, ID da Quero uma Agência); dados de falha; dados de cookies e as páginas que você visualizou ou interagiu antes e depois de acessar ou usar a Plataforma.</p>
                                    <p>1.3.4. Cookies, pixel e tecnologias semelhantes, conforme descrito em nossa Política de Cookies.</p>
                                    <ol>
                                        <li><b>QUAIS SÃO OS DADOS PESSOAIS TRATADOS PELA QUERO UMA AGÊNCIA EM NOME DE TERCEIROS</b></li>
                                    </ol>
                                    <p>2.1. Tratamento de Dados Pessoais a Pedido de Terceiros. Existem dados pessoais que são tratados pela Quero uma Agência a pedido de terceiros. A Quero uma Agência realiza o tratamento desses dados pessoais seguindo regras formalizadas com esses terceiros por meio de contratos escritos. Por exemplo, o Produtor pode solicitar, através da Plataforma, dados pessoais adicionais ao Comprador. Os tipos de Dados Pessoais coletados em nome do Produtor podem variar a depender da orientação específica do Produtor.</p>
                                    <p>2.2. Tratamento de Dados Pessoais relativos a Serviços do QuApay. A Quero uma Agência também pode tratar determinados dados pessoais em nome de terceiros quando você optar por utilizar os Serviços do QuApay. Em transações relativas ao Cartão Quero uma Agência, a Quero uma Agência pode realizar a coleta do número do cartão, nome do titular, validade, código CVV, CPF e informações da transação de pagamento em nome de uma instituição de pagamento parceira e da bandeira do cartão de crédito.</p>
                                    <p>2.3. Responsabilidade pelo Tratamento de Dados Pessoais por Terceiros. Os terceiros referidos nos itens anteriores também são agentes de tratamento responsáveis pelos dados pessoais no contexto do oferecimento de seus produtos e serviços.</p>
                                    <p>2.3.1. Em especial, o Usuário que optar pelo Cartão Quero uma Agência, apesar de não ter contato direto com a bandeira de cartão de crédito ou com a instituição de pagamento parceira da Quero uma Agência, possui um vínculo contratual com esses terceiros e, portanto, está vinculado também aos termos de uso e às políticas de privacidade aplicáveis a esses terceiros.</p>
                                    <ol>
                                        <li><b>QUAIS AS FINALIDADES DE TRATAMENTO DOS DADOS PESSOAIS</b></li>
                                    </ol>
                                    <p>3.1. Finalidades do Tratamento dos Dados Pessoais do Usuário. A Quero uma Agência utiliza os dados pessoais para as seguintes finalidades:</p>
                                    <p>3.1.1 Fornecer e desenvolver a Plataforma:</p>
                                    <p>Vincular sua conta na Quero uma Agência com redes sociais terceiras</p>
                                    <p>Personalizar a Plataforma de acordo com a experiência de navegação</p>
                                    <p>Realizar análises e estudos relacionados ao comportamento, interesse e demografia</p>
                                    <p>Melhorar a Plataforma</p>
                                    <p>Arquivar histórico de compra</p>
                                    <p>Atualizar cadastro</p>
                                    <p>Enviar mensagens, atualizações e notificações da conta</p>
                                    <p>Realizar atividades de suporte ao Usuário</p>
                                    <p>Permitir que o Usuário publique seus conteúdos</p>
                                    <p>Divulgar o conteúdo do Usuário pelos meios disponíveis na Plataforma</p>
                                    <p>3.1.2. Fornecer e otimizar as atividades de publicidade e marketing</p>
                                    <p>Realizar cadastro em eventos</p>
                                    <p>Enviar newsletter</p>
                                    <p>Enviar comunicações via e-mail, SMS ou gravações telefônicas</p>
                                    <p>Enviar materiais educativos ou e-books</p>
                                    <p>Realizar campanhas de mídia com foco em publicidade e promoções</p>
                                    <p>Enviar, manualmente, listas com os veículos de mídia (Facebook e Google)</p>
                                    <p>Enviar comunicação, tais como e-mail marketing, remarketing e outras campanhas de publicidade nos canais de mídia</p>
                                    <p>Analisar o comportamento de navegação para otimização da Plataforma e oferta de produtos e serviços</p>
                                    <p>3.1.3. Viabilizar os Serviços do QuApay</p>
                                    <p>Realizar comunicação ligada ao pagamento, como, por exemplo, envio de e-mails transacionais</p>
                                    <p>Participar do arranjo com as bandeiras de cartão</p>
                                    <p>Emitir moeda eletrônica</p>
                                    <p>Credenciar e emitir instrumento de pagamento</p>
                                    <p>Gerir conta de pagamento</p>
                                    <p>Converter moeda física em moeda eletrônica</p>
                                    <p>Executar remessa de fundos</p>
                                    <p>Aplicar as condições dos termos de uso do cartão de crédito e demais políticas envolvendo o Cartão Quero uma Agência</p>
                                    <p>3.1.4. Manter um ambiente seguro</p>
                                    <p>Realizar autenticação junto à Plataforma</p>
                                    <p>Verificar autenticidade dos dados e documentos fornecidos, inclusive mediante tratamento e verificação cruzada</p>
                                    <p>Enviar comunicados</p>
                                    <p>Realizar atividades de análise de prevenção a fraudes, incidentes de segurança e abusos na Plataforma</p>
                                    <p>Verificação de cadastro</p>
                                    <p>Verificação de conteúdo</p>
                                    <p>3.2. Finalidades do Tratamento dos Dados Pessoais de Visitante. A Quero uma Agência pode coletar dados pessoais de visitantes da Plataforma para as seguintes finalidades:</p>
                                    <p>Campanhas de mídia com foco em publicidade e promoções</p>
                                    <p>Enviar comunicação, tais como e-mail marketing, remarketing e outras campanhas de publicidade nos canais de mídia</p>
                                    <p>Analisar seu comportamento de navegação na Plataforma</p>
                                    <p>Realizar cadastro em eventos</p>
                                    <p>Enviar newsletter</p>
                                    <p>Enviar comunicações via e-mail, SMS ou gravações telefônicas</p>
                                    <p>Enviar materiais educativos ou e-books</p>
                                    <ol>
                                        <li><b>BASES LEGAIS DE TRATAMENTO</b></li>
                                    </ol>
                                    <p>4.1. Bases Legais. O tratamento de dados pessoais realizado pela Quero uma Agência é sempre fundamentado em uma base legal prevista nas legislações aplicáveis. As bases legais utilizadas pela Quero uma Agência podem variar a depender do contexto de tratamento. Abaixo as bases legais utilizadas pela Quero uma Agência:</p>
                                    <p>4.1.1. Consentimento. Quando o Usuário manifestamente aceita o tratamento de dados pessoais, como, por exemplo, quando concorda com o recebimento de newsletters, comunicados publicitários ou pesquisas. Esse consentimento pode ser revogado a qualquer momento pelo Usuário.</p>
                                    <p>4.1.2. Cumprimento de obrigação legal ou regulatória. Quando a Quero uma Agência é obrigada a realizar o tratamento de dados pessoais em razão de determinação legal ou regulatória, como, por exemplo, quando a Quero uma Agência se utiliza de ferramentas de terceiros para realizar avaliações creditícias dos Usuários. Nesse cenário, poderá existir decisões automatizadas para análise da possibilidade de antecipação de valores na Plataforma.</p>
                                    <p>4.1.3. Execução do Contrato ou procedimentos relacionados ao Contrato. Quando a Quero uma Agência é obrigada a realizar o tratamento de dados pessoais em razão dos Contrato aplicável entre a Quero uma Agência e o Usuário.</p>
                                    <p>4.1.4. Exercício regular de direitos em processo judicial, administrativo ou arbitral. Quando houver a necessidade de tratamento de dados pessoais em razão de ação judicial, procedimento administrativo ou arbitral.</p>
                                    <p>4.1.5. Legítimo interesse. Quando a Quero uma Agência decide prosseguir com o tratamento dos dados pessoais após análise criteriosa envolvendo a avaliação da finalidade e necessidade do tratamento ao Usuário, a expectativa do Usuário quanto ao tratamento dos dados pessoais e a elaboração da documentação legal necessária. Essa base legal é utilizada quando, por exemplo, a Quero uma Agência envia uma newsletter relacionada aos interesses do Usuário, considerando a sua interação com a Plataforma. Nesse caso, a Quero uma Agência sempre viabiliza ao Usuário a opção de cancelar o recebimento dessas comunicações.</p>
                                    <ol>
                                        <li><b>PERÍODO DE RETENÇÃO DOS DADOS PESSOAIS</b></li>
                                    </ol>
                                    <p>5.1. Período de Tratamento. Os dados pessoais são tratados pela Quero uma Agência até que pelo menos uma das hipóteses abaixo descritas seja atingida. São elas:</p>
                                    <p>Alcance da finalidade de tratamento</p>
                                    <p>Os dados pessoais deixarem de ser necessários ou pertinentes ao alcance da finalidade específica almejada</p>
                                    <p>Término do Contrato com o Usuário, seja qual for o motivo</p>
                                    <p>Exercício do direito de revogação do consentimento pelo Usuário, nas hipóteses que o tratamento de dados pessoais foi realizado com fundamento nessa base legal</p>
                                    <p>Eventual penalização aplicada pelo órgão responsável por zelar, implementar e fiscalizar o cumprimento da legislação aplicável, ou por demais órgãos competentes</p>
                                    <p>5.2. Conservação dos Dados Pessoais. Em determinadas situações, a Quero uma Agência e os terceiros parceiros da Quero uma Agência podem conservar residualmente os dados pessoais mesmo após as hipóteses de término de tratamento dos dados pessoais, em virtude de cumprimento de obrigação legal ou regulatória, ou ainda em circunstâncias em que haja uma finalidade legítima da Quero uma Agência ou desses terceiros. Nesses casos, os dados pessoais serão armazenados em local seguro com acesso restrito e pelo tempo necessário ao cumprimento da obrigação legal ou regulatória, ou ao término da finalidade legítima da Quero uma Agência e dos terceiros.</p>
                                    <ol>
                                        <li><b>COMPARTILHAMENTO DOS DADOS PESSOAIS</b></li>
                                    </ol>
                                    <p>6.1. Compartilhamento. A Quero uma Agência pode compartilhar os dados pessoais com terceiros. Esses terceiros celebram contrato por escrito com a Quero uma Agência e estão cientes das suas responsabilidades em cada etapa do tratamento, principalmente do dever de implementar medidas de segurança que atendam às exigências do contrato e das legislações aplicáveis. O compartilhamento pode ocorrer, por exemplo, nas situações descritas abaixo.</p>
                                    <p>6.1.1. Consentimento do Usuário. Quando você dá seu consentimento, compartilhamos suas informações conforme descrito no momento do consentimento – como, por exemplo, ao autorizar o envio de dados pessoais a terceiros para viabilizar participação em eventos.</p>
                                    <p>6.1.2. Cumprimento da lei, prevenção de danos e segurança. Quando há necessidade de compartilhamento de dados pessoais com terceiros a fim de (a) prevenir fraudes (como, por exemplo, análises de risco de lavagem de dinheiro), o que pode envolver decisão automatizada; (b) verificar lista de Usuários com restrições; (c) autenticar documentação encaminhada; (d) possibilitar atendimento de suporte; (e) evitar atividades ilegais; (f) proceder acerca de disputas entre Usuários; (g) proceder acerca de investigações internas relativas a qualquer conduta que possa violar as regras dos Termos ou das Políticas da Quero uma Agência.</p>
                                    <p>6.1.3. Serviços do QuApay. Os dados pessoais também são compartilhados com terceiros quando o usuário, de alguma forma, faz uso dos Serviços do QuApay, em especial quando o Usuário optar pelo Cartão Quero uma Agência. Os dados pessoais, incluindo o número do cartão, serão compartilhados com a bandeira do cartão de crédito e com a instituição de pagamento parceira da Quero uma Agência. Esse compartilhamento tem como finalidade processar o pagamento, disponibilizar os serviços de cartão, prevenir, detectar e investigar eventuais fraudes ou listas de restrições, facilitação em resolução de disputas envolvendo reembolso ou chargeback.</p>
                                    <p>6.1.4. Resposta a solicitações legais. Em determinados casos, se necessário, a Quero uma Agência pode fornecer os dados coletados aos órgãos e entidades que intervenham na investigação ou na resolução de disputas entre Usuários, ou entre Usuários e terceiros, tais como órgãos do Poder Judiciário, tribunais arbitrais ou autoridades administrativas competentes.</p>
                                    <p>6.1.5. Entre Sociedades ligadas à Quero uma Agência. O compartilhamento dos dados pessoais pode ocorrer entre as empresas controladoras, controladas, coligadas ou sob controle comum da Quero uma Agência, para viabilizar os Serviços da Quero uma Agência.</p>
                                    <p>6.1.6. Prestadores de Serviços. A Quero uma Agência também pode compartilhar os seus dados pessoais com terceiros envolvidos nas atividades de assessoria financeira, contábil, jurídica, atividades sem fins lucrativos e potenciais investidores, bem como terceiros envolvidos na prestação de backup de dados, provedores de serviços de computação em nuvem ou para realizar estudos analíticos.</p>
                                    <ol>
                                        <li><b>INTEGRAÇÕES E TERCEIROS PARCEIROS</b></li>
                                    </ol>
                                    <p>A Quero uma Agência, em determinados casos, pode vincular serviços de terceiros à Plataforma por meio de application programming interface (APIs), como, por exemplo, a API do YouTube. Estes serviços de terceiros não integram o escopo dos serviços da Quero uma Agência e não fazem parte da Plataforma, estando sujeitos a termos e condições diferentes, como os Termos de Serviço do YouTube e a Política de Privacidade do Google. A Quero uma Agência não é responsável por estes serviços e, quando você interage com eles, está fornecendo-lhes seus dados pessoais diretamente.</p>
                                    <ol>
                                        <li><b>TRANSFERÊNCIAS INTERNACIONAIS</b></li>
                                    </ol>
                                    <p>8.1. Transferências Internacionais. A Quero uma Agência e seus terceiros parceiros podem transferir os seus dados pessoais a países diferentes daquele onde você está localizado. Nesse caso, a transferência internacional dos dados pessoais será realizada para um país que proporcione grau de proteção de dados pessoais adequado. A Quero uma Agência e seus terceiros parceiros adotam as medidas exigidas por lei para assegurar a proteção desses dados pessoais, bem como que a transferência seja realizada de acordo com um dos mecanismos de transferência previstos na legislação aplicável.</p>
                                    <ol>
                                        <li><b>DIREITOS COM RELAÇÃO AOS SEUS DADOS PESSOAIS</b></li>
                                    </ol>
                                    <p>9.1. Solicitações: Você pode encaminhar uma solicitação relacionada aos seus direitos previstos na legislação aplicável. Esses direitos podem incluir o pedido de confirmação de tratamento de dados pessoais, acesso aos dados pessoais, retificação dos dados pessoais e eliminação de dados pessoais, o que deve ser feito através deste formulário.</p>
                                    <p>Para falar com o nosso responsável pela proteção de dados pessoais, entre em contato através deste formulário.</p>
                                    <p>Sempre que a Quero uma Agência estiver atuando em nome de terceiros, e o Usuário decidir buscar junto à Quero uma Agência o atendimento a um de seus direitos, poderemos atender, em certas circunstâncias, a essa solicitação, caso aplicável.</p>
                                    <p>Se você desejar cancelar o envio de newsletters, e-mails, telefonemas ou SMS publicitários, poderá fazê-lo através do link fornecido nas próprias comunicações.</p>
                                    <p>Se você desejar eliminar os cookies instalados, a Política de Cookies irá mostrar-lhe como fazer isso.</p>
                                    <ol>
                                        <li><b>SEGURANÇA</b></li>
                                    </ol>
                                    <p>10.1. Medidas de Segurança. A Quero uma Agência emprega medidas de segurança, técnicas e organizacionais necessárias para proteger os dados pessoais. Os esforços visam mitigar riscos de destruição, perda acidental ou ilegal, alteração, divulgação ou acesso não autorizado ou qualquer outra forma de tratamento ilegal ou não autorizado ou não adequado. Para isso, a Quero uma Agência conta com controles rígidos de acesso restrito, baseados no menor privilégio, aos sistemas de tecnologia da informação para proteger os dados pessoais contra eventual incidente de segurança.</p>
                                    <ol>
                                        <li><b>TRATAMENTO DE DADOS PESSOAIS DE MENORES</b></li>
                                    </ol>
                                    <p>11.1. Dados Pessoais de Menores. A Quero uma Agência não coleta nem solicita conscientemente dados pessoais de menores de 13 anos. Se tomarmos conhecimento de que dados pessoais de um menor de 13 anos foram coletados, cancelaremos a conta criada na Plataforma e excluiremos os dados pessoais, apenas mantendo dados pessoais que tenham como objetivo evitar nova tentativa de cadastro.</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PoliticaPrivacidade
