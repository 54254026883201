import React, { Component } from 'react'
import $ from 'jquery'
import {connect} from 'react-redux'

export class Cookies extends Component {

    componentDidMount() {  
        $(function(){
            $('.btn').click(function(){
                $('.cookies').hide();
            });
        })    
    }

    render() { 
        const armazenar=(chave, valor)=>{
            localStorage.setItem(chave, valor)
        }
        const aceitouCookies = localStorage.getItem('COOKIES_POLICY');
        return (
            aceitouCookies ? '' : <div className='navbar navbar-dark bg-dark cookies'>
                    <p className='navbar-brand'>Este site utiliza cookies para melhorar a qualidade da sua experiência
                        de navegação. Saber mais sobre <a href='/politica-cookies'>
                            <u>Política de Cookies</u></a> e <a href='/politica-privacidade'><u>Privacidade</u></a>.
                    </p>
                    <button type='button' className='btn btn-outline-primary'
                                onClick={() => armazenar('COOKIES_POLICY', true)}>Entendi
                        </button>
                </div>)
    }
}

export function mapStateToProps() {
    return {}
  }
  
export default connect(mapStateToProps)(Cookies)
  
    
