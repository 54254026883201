let browserHistory;

export const setBrowserHistory = (history) => {
    browserHistory = history;
};

const ambienteKey = 'ambienteKey';
const minhaAgencia = 'minhaAgencia'


export const getBrowserHistory = () => browserHistory;

export const salvarAmbiente = (ambiente) => {
    localStorage.setItem(ambienteKey, ambiente)
};

export const salvarMinhaAgencia = (agencia) => {
    localStorage.setItem(minhaAgencia, JSON.stringify(agencia))
};

export const removerMinhaAgencia = () => {
    localStorage.removeItem(minhaAgencia)
};

export const getMinhaAgencia = () => {
    try {
        const item = localStorage.getItem(minhaAgencia);
        return item ? JSON.parse(item) : item
    } catch (err) {
        console.log(err);
        throw err
    }
};

export const removerAmbiente = () => {
    localStorage.removeItem(ambienteKey)
};



