import React, {Component} from 'react'
import {connect} from 'react-redux'
import logoAgencia from '../../estilo/comum/img/logo_quero_uma_agencia.png'
import {withRouter} from 'react-router-dom';

export class BarraNavegacao extends Component {
    render() {
        return(
            <div className='navbar navbar-expand-lg navbar-ligth'>
            <div className='container navegacao'>
                <a href='/'>
                    <img className='logo' src={logoAgencia}
                        alt='Logo Agência' style={{width: '406px'}}/>
                </a>
            </div>
            </div>
        )
}
}

export const mapStateToProps = () => (
    {}
)

export default withRouter(connect(mapStateToProps, {})(BarraNavegacao))
