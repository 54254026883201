import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import styles from './MessagesBarStyles';
import { dismissMessage } from '../../actions/messagesActions';
import {backgroundColorsMessages} from '../../util/messagesUtil';



export class MessagesBar extends React.Component {
  handleClose(reason) {
    if (reason !== 'clickaway') {
      this.props.dismissMessage();
    }
  }

  render() {
    const { classes } = this.props;
    const { message } = this.props.messages;
    const open = Boolean(message) && !message.modal;
    const backgroundColor = open ? backgroundColorsMessages[message.type] || backgroundColorsMessages.default : backgroundColorsMessages.none;
    const msgText = open ? message.msgText : null;
    return (<Snackbar
      anchorOrigin={{
        vertical: 'bottom', horizontal: 'center'
      }}
      ContentProps={{ style: { backgroundColor, color: '#fff', flexWrap: 'noWrap' }, elevation: open ? 6 : 0 }}
      open={open}
      autoHideDuration={20000}
      onClose={(event, reason) => this.handleClose(reason)}
      message={<span id='message-id'>{msgText}</span>}
      horizontal='center'
      action={[<IconButton
        key='close'
        aria-label='Close'
        color='inherit'
        style={{ padding: 8, marginBottom: 50}}
        onClick={this.props.dismissMessage}>
        <CloseIcon className={classes.close}/>
      </IconButton>]}
    />);
  }
}

const materialUIEnhance = withStyles(styles)(MessagesBar);

const mapStateToProps = ({ messages }) => ({ messages });

export default connect(mapStateToProps, { dismissMessage })(materialUIEnhance);
