import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {setBrowserHistory} from '../util/applicationContext'
import PaginaPrincipal from '../componentes/layout/PaginaPrincipal';
import PoliticaPrivacidade from '../componentes/PoliticaPrivacidade'
import PoliticaCookies from '../componentes/PoliticaCookies'
import TermosUso from '../componentes/TermosUso'
import FormularioQueroMinhaAgencia from '../componentes/agencia/FormularioQueroMinhaAgencia';
import ReciboPagamento from '../componentes/agencia/ReciboPagamento';

export class AppRoutes extends React.Component {
    constructor(props) {
        super(props);
        const {history} = this.props;
        setBrowserHistory(history)
    }

    render() {
        return (
            <Switch>
                <Route path='/recibo-pagamento' component={ReciboPagamento}/>
                <Route path='/politica-privacidade' component={PoliticaPrivacidade}/>
                <Route path='/politica-cookies' component={PoliticaCookies}/>
                <Route path='/termos-uso' component={TermosUso}/>
                <Route path='/quero-minha-agencia-gratis' component={FormularioQueroMinhaAgencia}/>
                <Route path='/' component={PaginaPrincipal}/>
            </Switch>
        )
    }
}

export default withRouter(AppRoutes)
