import { SLOW_REQUEST_CONFIG } from './loadingUtil';

import axios from 'axios';
import {salvarAmbiente} from './applicationContext';


export const BUSCAR_AMBIENTE = '@ambienteActions/BUSCAR_AMBIENTE';
export const AMBIENTE_INICIADO = '@ambienteActions/AMBIENTE_INICIADO';
export const AMBIENTE_DESCARTADO = '@ambienteActions/AMBIENTE_DESCARTADO';

const URL = process.env.NODE_ENV === 'production'
    ? 'https://agencia-api.vexado.com.br'
    : 'http://localhost:8083';

const VERSION = 'master';
export { URL, VERSION }

export const ambienteUrl = `${URL}/ambiente`;

export const recuperarAmbiente = callback => dispatch => axios.get(ambienteUrl, SLOW_REQUEST_CONFIG)
    .then((response) => {
        const ambiente = response.data;
        salvarAmbiente(ambiente);
        if (callback) {
            callback();
        }
        return dispatch({
            type: BUSCAR_AMBIENTE,
            payload: ambiente
        });
    });

