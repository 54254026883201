import React from 'react'
import { change as changeFieldValue } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import qs from 'qs';
import {formatarReal} from '../../util/arraysUtil';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');


export const validate = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Campo obrigatório'
    }
    if (!values.email) {
        errors.email = 'Campo obrigatório'
    }

    return errors
};

export class ReciboPagamento extends React.Component {
    copyLink(event) {
        const {pedido} = this.props.minhaAgencia;
        event.preventDefault();
        navigator.clipboard.writeText(pedido.formasPagamentoDto[0].linkPagamento);

    }

    exibirPagamentoPix() {
        const {pedido} = this.props.minhaAgencia;
        if (!pedido) return '';
        return (
            <div className='modal-body'>
                <div className="form-row">
                    <div className="col-12 col-sm-12 col-md-5" style={{textAlign: 'center'}}>
                        <img className='form-group w-75'
                             src={pedido.formasPagamentoDto[0].qrcode}
                        />
                    </div>
                    <div className="col-12 col-sm-5 col-md-6">
                        <p className='form-group'>
                            <strong>Pedido: </strong> {pedido.id}
                        </p>
                        <p className='form-group'>
                            <strong>Valor do pagamento: </strong> {formatarReal(pedido.formasPagamentoDto[0].valor.toString())}
                        </p>
                        <p>
                            <button type='button' id="copy-past-qr-code"
                                    onClick={(e)=> this.copyLink(e)}
                                    style={{cursor: 'pointer'}}
                                    className="btn btn-laranja p-3 col-sm-12">
                                Copiar link para pagamento
                            </button>
                        </p>
                        <p className='form-group col-12 col-sm-12'>
                            - Se o pagamento não for feito em até 1 hora, o seu pedido será cancelado.
                        </p>
                    </div>
                </div>
                <div className="form-row"
                     onClick={(e)=> this.copyLink(e)}>
                    <div className='row mt-2 p-2'
                         style={{backgroundColor: '#e1e1e1', cursor: 'pointer'}}
                         title='Copiar'>
                                    <span style={{fontSize: 11.5, textAlign: 'center'}}>
                                        {pedido.formasPagamentoDto[0].linkPagamento}
                                    </span>
                    </div>
                </div>
                {this.exibirInstrucaoPix()}
            </div>
        );
    }

    exibirInstrucaoPix() {
        return (<div><h3 className='m-3'>Instruções</h3>
            <div className="col mt-2">
                <p>
                    - Abra o aplicativo de pagamento do seu celular e <b>escaneie o QR Code</b> (código QR). Após, no aplicativo de pagamento, é só confirmar.
                    <br />
                    - Com o pagamento confirmardo, você receberá as informações da viagem por e-mail
                </p>
            </div>
            <div className='col'>
                <h4>Pagamento com o PIX copiando o link</h4>
                <p>
                    - Clique no botão <strong>Copiar link para pagamento</strong><br />
                    - Entre no site de pagamento de sua preferência e insira o código onde precisar.<br />
                    - Com o pagamento confirmado, você receberá as informações da viagem por e-mail.<br />
                </p>
            </div>
        </div>)
    }

    ehPagamentoPix(parametros){
        return parametros && parametros.formaPagamento === 'PIX'
    }

    ehPlanoPago(parametros) {
        return parametros && parametros.plano !== 'BASICO'
    }

    render() {
        const parametros = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        return (
            <div className='container'>
                <div>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5>{this.ehPagamentoPix(parametros)? 'Realizar pagamento com Pix' : 'Parabéns por adquirir sua bilheteria digital!' }</h5>
                        </div>
                        <div className='modal-body'>
                            {this.ehPagamentoPix(parametros) ? this.exibirPagamentoPix() :
                                <div className='text-center mt-5'>
                                    <h3 className='m-3'>Instruções (FIQUE ATENTO AO SEU E-EMAIL)</h3>
                                    {this.ehPlanoPago(parametros) ?
                                        <h4 className='text-warning mb-4'><b><u>Estamos aguardando a confirmação com a sua operadora de cartão</u></b></h4>
                                        : ''}
                                    <div className='col mt-2'>
                                        <p>
                                            - {this.ehPlanoPago(parametros) ? 'Assim que houver a confirmação, estaremos' : 'Estaremos'} enviando ao seu <b>e-mail cadastrado</b> o <b>usuário</b> e <b>senha</b> para você acessar o painel de <b>GESTÃO</b>.
                                            <br />
                                            - Logo abaixo segue o link do painel de gestão, no qual você conseguirá acessar com sua conta:
                                            <br />
                                            - Acesse o painel de <b>gestão</b> <a href='https://gestaoagencia.com/login'><b>clicando aqui</b></a>.
                                        </p>
                                        <div className='mt-5'>
                                            <p>
                                                <b>E não se preocupe, também estamos enviando esse link de acesso ao seu e-mail, juntamente com seu usuário e senha.</b>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col mt-5 mb-5'>
                                        <h4 className='text-justify-center'><b>Seja bem-vindo ao mundo tecnológico e digital</b></h4>
                                        <h5><b>Boas vendas!</b></h5>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        minhaAgencia: state.minhaAgencia
    }
}

export const mapDispatchToProps = dispatch => (
    bindActionCreators({
        changeFieldValue,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ReciboPagamento);
