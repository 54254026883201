import React from 'react'
import NumberFormat from 'react-number-format';
import { mascaraMoeda } from './arraysUtil';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import Select from 'react-select';
import moment from 'moment'
import validador from 'br-validations';
import {validateCard} from './cartaoUtil';

export function required (value) {
    return value && value.length > 0 ? undefined : 'Campo obrigatório';
}

export function requiredSelect(value) {
    return value && value.value ? undefined : 'Campo obrigatório';
}


export function maxValueTelefone (value) {
    if (value && value.replace(/[^\d]/g, '').length != 11) {
        return 'Campo inválido';
    }
    return undefined;
}

export function maxValueData (value) {
    if (value && value.replace(/[^\d]/g, '').length != 8) {
        return 'Campo inválido';
    }
    return undefined;
}

export function minCartao(value) {
    const ehValido = validateCard(value)
    if (!ehValido) {
        return 'Número de cartão inválido';
    }
    return undefined;
}

export function validateMinutos (value) {
    const minutos = Number(value.split(':')[1])

    return minutos > 59 ? 'Minutos não pode ser maior que 59' : undefined;
}

export function validateHora (value) {
    const minutos = Number(value.split(':')[0])

    return minutos > 23 ? 'Hora não pode ser maior que 23' : undefined;
}

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'E-mail inválido' : undefined;

export function telefone (value) {
    if (apenasNumero(value).length !== 10 && apenasNumero(value).length !== 11) {
        return 'Telefone inválido'
    }
    return '';
}

export function data (value) {
    if (!moment(value, 'DD/MM/YYYY', true)
        .isValid()) {
        return 'Data inválida'
    }
    return undefined
}

export function cpfCnpj (value) {
    if (apenasNumero(value).length !== 11 && apenasNumero(value).length !== 14) {
        if (apenasNumero(value).length < 11) {
            return 'CPF inválido'
        }
        return 'CNPJ inválido'
    }
    return '';
}

export function minTamanhoCodigoEmpresa(value) {
    if(!value || value.length < 3) {
        return 'Código empresa deve haver 3 dígitos'
    }
    return undefined
}

export function apenasLetra (value) {
    if(value && value.match(/[\d]/g)){
        return 'O campo não pode conter caracteres numéricos'
    }
    return undefined;
}

export function apenasNumero (value) {
    if (value) {
        return value.replace(/[^\d]/g, '');
    }
    return '';
}

export function minTamanhoNome (value) {
    if (!value || value.length < 2) {
        return 'Quantidade de caracteres deve ser maior que 1';
    }
    return undefined;
}

export function minDocumento (value) {
    if (!value || value.length < 2) {
        return 'Quantidade de caracteres deve ser maior que 1';
    }
    return undefined;
}

export function minTamnhoSenha (value) {
    if (!value || value.length < 8) {
        return 'Quantidade de caracteres deve ser maior ou igual a 8.';
    }
    return undefined;
}

export function minTamnhoIbge (value) {
    if (!value || value.length !== 7) {
        return 'Quantidade de caracteres deve ser igual a 7.';
    }
    return undefined;
}

export const requiredCPF = value => {
    return (value && validador.cpf.validate(value) ? undefined : 'CPF inválido');
};

export function validarCpfCnpj (value) {
    if (apenasNumero(value).length > 11) {
        return validarCNPJ(value)
    }
    return validarCPF(value)

}

export function validarCPF (cpf) {
    const cpfInvalido = 'CPF inválido!';
    if (!cpf || apenasNumero(cpf).length !== 11) {
        return cpfInvalido;
    }
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf.length !== 11 ||
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999') {
        return cpfInvalido;
    }
    let add = 0;
    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
        rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(9))) {
        return cpfInvalido;
    }
    add = 0;
    for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
        rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(10))) {
        return cpfInvalido;
    }
    return undefined;
}

export function validarCNPJ (cnpj) {

    const cnpjInvalido = 'CNPJ inválido!';
    if (!cnpj || apenasNumero(cnpj).length !== 14) {
        return cnpjInvalido;
    }

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) {
        return cnpjInvalido;
    }

    if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') {
        return cnpjInvalido;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
        return cnpjInvalido;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
        return cnpjInvalido;
    }

    return undefined;

}

export function idadeMinCriancaColo (value) {
    if (value &&
        moment()
            .diff(moment(value, 'DD/MM/YYYY'), 'years', false) > 6) {
        return 'Idade sem gratuidade';
    }

    return undefined

}

export function dataValidade (value) {
    if (!value) {
        return 'Informar validade';
    }
    if (value && moment()
        .isAfter(moment(value, 'DD/MM/YYYY')
            .subtract(1, 'day'))) {
        return 'Carteira vencida'
    }
    return undefined

}

export function idadeMinIdoso (value) {
    if (value &&
        moment()
            .diff(moment(value, 'DD/MM/YYYY'), 'years', false) < 60) {
        return 'Idade sem gratuidade';
    }

    return undefined

}

export function tamanhoPorcentagem (value) {
    if (value && value.trim().length != 5) {
        return 'Campo inválido';
    }

    return undefined;

}

export function tamanhoHoraMinuto (value) {
    if (value && value.trim().length != 5) {
        return 'Campo inválido';
    }

    return undefined;

}

export const renderFieldHora = ({
                                    input,
                                    label,
                                    css,
                                    max,
                                    readOnly,
                                    meta: {touched, error}
                                }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format="##:##"
                          className={css}
                          maxLength={max}
                          style={{borderColor: touched && error ? 'red' : ''}}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{color: 'red'}}>{error}</span>)}
        </div>
    </div>
);

export const renderField = ({
                                input,
                                label,
                                type,
                                required,
                                css,
                                max,
                                readOnly,
                                meta: { touched, error }
                            }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <input {...input} type={type}
                   id={input.name}
                   className={css}
                   required={required}
                   maxLength={max}
                   style={{ borderColor: touched && error ? 'red' : '' }}
                   disabled={readOnly}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);


export const renderFieldMonetarioFormulario = ({
                                                   input,
                                                   label,
                                                   css,
                                                   readOnly,
                                                   handleChange,
                                                   meta: { touched, error }
                                               }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <CurrencyInput
                {...input}
                className={css}
                prefix="R$" decimalSeparator=","
                value={input.value}
                thousandSeparator="."
                onChangeEvent={handleChange}
                disabled={readOnly}
                readOnly={readOnly}/>
            {touched
            && (error && <strong style={{
                color: 'red',
                fontSize: 10
            }}>{error}</strong>)}
        </div>
    </div>
);


export const renderFieldSelect = ({
                                      input,
                                      placeholder,
                                      options,
                                      label,
                                      isClearable,
                                      disabled,
                                      meta: { touched, error }
                                  }) => (<div>
    <div>
        {label ? <label className='mt-2'>{label}</label> : ''}
        <Select {...input}
                id={input.name}
                isClearable={isClearable}
                placeholder={placeholder}
                styles={{
                    control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 32,
                        fontSize: 16,
                        width: '100%',
                        borderColor: '#e4e7ea',
                        cursor: 'pointer'
                    })
                }}
                value={input.value}
                isDisabled={disabled}
                noOptionsMessage={() => ('Nenhuma opção encontrada')}
                options={options.map(elemento => ({
                    label: elemento.descricao,
                    value: elemento.nome
                }))}
                onChange={value => input.onChange(value)}
                onBlur={() => input.onBlur()}
        />
        {touched
        && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
</div>);

export const renderFieldEmail = ({
                                     input,
                                     label,
                                     type,
                                     css,
                                     max,
                                     readOnly,
                                     meta: { touched, error }
                                 }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>

            <input {...input} type={type}
                   className={css}
                   value={input.value.toLowerCase()}
                   maxLength={max}
                   id={input.name}
                   style={{ borderColor: touched && error ? 'red' : '' }}
                   disabled={readOnly}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldCheck = ({
                                     input,
                                     label,
                                     type,
                                     css,
                                     max,
                                     readOnly,
                                     meta: { touched, error }
                                 }) => (


    <div className='form-group form-check'>

        <input {...input} type={type}
               className={css}
               maxLength={max}
               id={input.name}
               style={{ borderColor: touched && error ? 'red' : '' }}
               disabled={readOnly}
               readOnly={readOnly}/>
        {touched
        && (error && <span style={{ color: 'red' }}>{error}</span>)}
        <label className='form-check-label'>{label}</label>
    </div>
);

export const renderFieldCpf = ({
                                   input,
                                   label,
                                   css,
                                   max,
                                   readOnly,
                                   placeholder,
                                   meta: { touched, error }
                               }) => (
    <div>
        {label ? <label className='mt-2'>{label}</label> : ''}
        <div>
            <NumberFormat {...input} format="###.###.###-##"
                          className={css}
                          maxLength={max}
                          placeholder={placeholder}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);


export const renderFieldMoeda = ({
                                     input,
                                     label,
                                     type,
                                     css,
                                     max,
                                     cod,
                                     readOnly,
                                     meta: { touched, error }
                                 }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <input {...input} type={type}
                   className={css}
                   maxLength={max}
                   value={mascaraMoeda(input.value)}
                   style={{ borderColor: touched && error ? 'red' : '' }}
                   disabled={readOnly || cod}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldNumeros = ({
                                       input,
                                       label,
                                       css,
                                       max,
                                       readOnly,
                                       valor,
                                       formatacao,
                                       placeholder,
                                       meta: { touched, error }
                                   }) => (
    <div>
        {label ? <label className='mt-2'>{label}</label> : ''}
        <div>
            <NumberFormat {...input} format={formatacao ? formatacao : '###########'}
                          className={css}
                          maxLength={max}
                          placeholder={placeholder}
                          id={input.name}
                          value={valor}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldNumerosPorcentagem = ({
                                                  input,
                                                  label,
                                                  css,
                                                  readOnly,
                                                  meta: { touched, error }
                                              }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format={'##.##'}
                          className={css}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldNumber = ({
                                      input,
                                      label,
                                      css,
                                      max,
                                      readOnly,
                                      formatacao,
                                      meta: { touched, error }
                                  }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input}
                          format={formatacao}
                          className={css}
                          id={input.name}
                          maxLength={max}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);


export const renderFieldCnpj = ({
                                    input,
                                    label,
                                    css,
                                    required,
                                    max,
                                    readOnly,
                                    meta: { touched, error }
                                }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input}
                          format="##.###.###/####-##"
                          className={css}
                          id={input.name}
                          required={required}
                          maxLength={max}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldCpfCnpj = ({
                                       input,
                                       label,
                                       css,
                                       readOnly,
                                       meta: { touched, error }
                                   }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input}
                          format={apenasNumero(input.value).length > 11 ? '##.###.###/####-##' : '###.###.###-###'}
                          className={css}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldDataNascimento = ({
                                              input,
                                              label,
                                              css,
                                              readOnly,
                                              meta: { touched, error }
                                          }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <InputMask {...input} mask="99/99/9999" value={input.value}
                       className={css}
                       style={{ borderColor: touched && error ? 'red' : '' }}
                       readOnly={readOnly}/>

            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldTelefone = ({
                                        input,
                                        label,
                                        css,
                                        max,
                                        readOnly,
                                        meta: { touched, error }
                                    }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format="(##) #####-####"
                          className={css}
                          maxLength={max}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldMonetario = ({
                                         input,
                                         label,
                                         readOnly,
                                         handleChange,
                                         meta: { touched, error }
                                     }) => (
    <div className="row align-items-center mt-2">
        <div className='col-md-6 label-cancelamento ml-2'>
            <strong>{label}:</strong>
        </div>
        <div className='col-md-6'>
            <CurrencyInput
                {...input}
                className='form-control'
                prefix="R$" decimalSeparator=","
                value={input.value}
                allowNegative={true}
                thousandSeparator="."
                onChangeEvent={handleChange}
                disabled={readOnly}
                readOnly={readOnly}/>
            {touched
            && (error && <strong style={{
                color: 'red',
                fontSize: 10
            }}>{error}</strong>)}
        </div>
    </div>
);

export const renderFieldMonetarioLabelEncima = ({
                                                    input,
                                                    label,
                                                    readOnly,
                                                    handleChange,
                                                    meta: { touched, error }
                                                }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <CurrencyInput
                {...input}
                className='form-control'
                prefix="R$" decimalSeparator=","
                value={input.value}
                allowNegative={true}
                thousandSeparator="."
                onChangeEvent={handleChange}
                disabled={readOnly}
                readOnly={readOnly}/>
            {touched
            && (error && <strong style={{
                color: 'red',
                fontSize: 10
            }}>{error}</strong>)}
        </div>
    </div>
);

export const renderFieldPercentual = ({
                                          input,
                                          label,
                                          readOnly,
                                          handleChange,
                                          meta: { touched, error }
                                      }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <CurrencyInput
                {...input}
                className='form-control'
                decimalSeparator="."
                value={input.value}
                allowNegative={false}
                onChangeEvent={handleChange}
                disabled={readOnly}
                readOnly={readOnly}/>
            {touched
            && (error && <strong style={{
                color: 'red',
                fontSize: 10
            }}>{error}</strong>)}
        </div>
    </div>
);

export const renderTextArea = ({
                                   input,
                                   label,
                                   readOnly,
                                   css,
                                   max,
                                   meta: { touched, error }
                               }) => (
    <div className='ml-2'>
        <strong className='mt-2'>{label}</strong>
        <div>
        <textarea {...input}
                  className={css}
                  rows='5'
                  id={input.name}
                  maxLength={max}
                  style={{ borderColor: touched && error ? 'red' : '' }}
                  disabled={readOnly}
                  readOnly={readOnly}/>
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>);

export const renderTextArea2 = ({
                                    input,
                                    label,
                                    readOnly,
                                    css,
                                    max,
                                    meta: { touched, error }
                                }) => (
    <div>
        <span className='mt-2'>{label}</span>
        <div>
        <textarea {...input}
                  className={css}
                  rows='5'
                  id={input.name}
                  maxLength={max}
                  style={{ borderColor: touched && error ? 'red' : '' }}
                  disabled={readOnly}
                  readOnly={readOnly}/>
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>);

export const renderFieldCep = ({
                                   input,
                                   label,
                                   css,
                                   max,
                                   readOnly,
                                   meta: { touched, error }
                               }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format="#####-###"
                          className={css}
                          maxLength={max}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldKM = ({
                                  input,
                                  label,
                                  readOnly,
                                  handleChange,
                                  meta: { touched, error }
                              }) => (
    <div>
        <label className='mt-2'>{label}</label>
        <div>
            <CurrencyInput
                {...input}
                className='form-control'
                decimalSeparator=","
                value={input.value}
                allowNegative={true}
                thousandSeparator="."
                onChangeEvent={handleChange}
                disabled={readOnly}
                readOnly={readOnly}/>
            {touched
            && (error && <strong style={{
                color: 'red',
                fontSize: 10
            }}>{error}</strong>)}
        </div>
    </div>
);

export const renderFieldUpload = ({
                                      input,
                                      handleChange, titulo,
                                      meta: { touched, error }
                                  }) => (
    <div>
        <div className='input-wrapper'>
            <label htmlFor='input-file'>
                {titulo}
            </label>
            <input {...input} id='input-file' type='file' value='' accept="image/*" onChange={handleChange}/>
            <span id='file-name'/>
            {touched
            && (error && <strong style={{
                color: 'red',
                fontSize: 10
            }}>{error}</strong>)}
        </div>
    </div>
);

export const renderFieldValidadeCartao = ({
                                              input,
                                              label,
                                              css,
                                              max,
                                              readOnly,
                                              meta: { touched, error }
                                          }) => (
    <div>
        <label className='mb-2 mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format='##/##'
                          className={css}
                          maxLength={max}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldCodigoSeguranca = ({
                                               input,
                                               css,
                                               max,
                                               readOnly,
                                               meta: { touched, error }
                                           }) => (
    <div>
        <div>
            <NumberFormat {...input} format="###"
                          className={css}
                          maxLength={max}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldNumeroCartao = ({
                                            input,
                                            label,
                                            css,
                                            max,
                                            readOnly,
                                            buscarParcelas,
                                            meta: { touched, error }
                                        }) => (
    <div>
        <label className='mb-2 mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format="#### #### #### ####"
                          className={css}
                          maxLength={max}
                          id={input.name}
                          onChange={buscarParcelas}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldSelectParcelas = ({
                                              input,
                                              placeholder,
                                              options,
                                              label,
                                              isClearable,
                                              disabled,
                                              meta: {touched, error},
                                          }) => (<div>
    <div>
        {label ? <label className='mb-2 mt-2'>{label}</label> : ''}
        <Select {...input}
                id={input.name}
                isClearable={isClearable}
                placeholder={placeholder}
                value={input.value}
                isDisabled={disabled}
                noOptionsMessage={() => ('Nenhuma opção encontrada')}
                options={options.map(elemento => ({
                    label: elemento.parcela,
                    value: elemento.qtdParcelas,
                    valorTotal: elemento.valorTotal,
                    qtdParcelas: elemento.qtdParcelas,
                    metodoPagamento: elemento.metodoPagamento,
                }))}
                onChange={value => input.onChange(value)}
                onBlur={() => input.onBlur()}
        />
        {touched
        && (error && <span style={{color: 'red'}}>{error}</span>)}
    </div>
</div>);

export const renderFieldText = ({
                                    input,
                                    label,
                                    type,
                                    css,
                                    max,
                                    placeholder,
                                    readOnly,
                                    meta: {touched, error},
                                }) => (
    <div>
        <label className='mb-2 mt-2'>{label}</label>
        <div>
            <input {...input} type={type}
                   className={css}
                   placeholder={placeholder}
                   maxLength={max}
                   value={input.value}
                   style={{borderColor: touched && error ? 'red' : ''}}
                   disabled={readOnly}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{color: 'red'}}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldData = ({
                                    input,
                                    label,
                                    css,
                                    max,
                                    readOnly,
                                    meta: { touched, error }
                                }) => (
    <div>
        <label className='mb-2 mt-2'>{label}</label>
        <div>
            <NumberFormat {...input} format="##/##/####"
                          className={css}
                          maxLength={max}
                          id={input.name}
                          style={{ borderColor: touched && error ? 'red' : '' }}
                          readOnly={readOnly}
            />
            {touched
            && (error && <span style={{ color: 'red' }}>{error}</span>)}
        </div>
    </div>
);
