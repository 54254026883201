import React, { Component } from 'react'
import { connect } from 'react-redux'
import logoFinal from '../../estilo/comum/img/logo-final.png'

export class Footer extends Component {


    render() {

        return (
            <footer>
                <div className="container rodape">
                    <div className="row">
                        <div className='col-md-6 logoFinal'>
                            <img src={logoFinal} style={{ height: '79px' }} />
                        </div>
                        <div className='col-md-6 container'>
                            <div className="acesso">
                                <div className='links'>
                                    <a rel='noreferrer' href="/">Home</a>
                                </div>
                                <div className='links'>
                                    <a rel='noreferrer' href="/politica-cookies">Política de Cookies</a>
                                </div>
                                <div className='links'>
                                    <a rel='noreferrer' href="/politica-privacidade">Política de Privacidade</a>
                                </div>
                                <div className='links'>
                                    <a rel='noreferrer' href="/termos-uso">TERMO DE USO</a>
                                </div>
                            </div>
                            <div id="redes">
                                <div className='links'>
                                    <a rel='noreferrer' href="https://www.facebook.com/queroumaagencia">Facebook</a>
                                </div>
                                <div className='links'>
                                    <a rel='noreferrer' href="https://www.instagram.com/queroumaagencia/">Instagram</a>
                                </div>
                                <div className='links'>
                                    <a rel='noreferrer' href="https://api.whatsapp.com/send?phone=5561981537600&text=Ol%C3%A1%2C%20venho%20do%20Quero%20uma%20Ag%C3%AAncia.%20">WhatsApp</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export const mapStateToProps = () => (
    {

    });


export default connect(mapStateToProps,
    {
    })(Footer)
