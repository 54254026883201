import React, { Component } from 'react'

class PoliticaCookies extends Component {
    render() {
        return (
            <div className='conteudo'>
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1>Política de Cookies</h1>
                                <div className="divider" />
                            </div>
                            <div className='card'>
                                <div className='card-body informacoes'>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PoliticaCookies
