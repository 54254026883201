import store from '../store/configureStore';
import { showMessage, dismissMessage } from '../actions/messagesActions';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';

export const ERROR = 'error';
export const INFO = 'info';
export const ALERT = 'alert';
export const SUCCESS = 'success';


export const labelTypeMessages = {
    error: 'Erro!',
    alert: 'Atenção!',
    success: 'Sucesso!'
};

export const backgroundColorsMessages = {
    error: red.A700,
    info: blue[500],
    alert: orange[900],
    success: green[500],
    default: blue[400],
    none: 'rgba(0, 0, 0, 0)'
};

const showMsg = (type, msgText, modal) => store.dispatch(showMessage({ type, msgText, modal }));

export const closeMsg = () => store.dispatch(dismissMessage());


export const showErrorMsg = (message, modal) => showMsg(ERROR, message, modal);

export const showInfoMsg = (message, modal) => showMsg(INFO, message, modal);

export const showAlertMsg = (message, modal) => showMsg(ALERT, message, modal);

export const showSuccessMsg = (message, modal) => showMsg(SUCCESS, message, modal);

export const houveDeterminadaExcecao = (error, siglaCodigoErro) => error
    && error.response
    && error.response.data
    && error.response.data.violacoes
    && error.response.data.violacoes.some(violacao => violacao.codigo === siglaCodigoErro);
