import React from 'react'
import { change as changeFieldValue, formValueSelector, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReCaptchaV2 from 'react-google-recaptcha'
import {
    cadastrarUsuario,
    recuperarOpcoesPagamento,
    recuperarTiposPlano,
    cadastrarUsuarioPagamentoPix
} from '../../actions/usuarioActions'
import {
    renderFieldDataNascimento,
    renderFieldCpf,
    renderFieldEmail,
    renderFieldSelect,
    renderFieldTelefone,
    required,
    requiredCPF,
    requiredSelect, renderField,
    renderFieldText,
    apenasLetra
} from '../../util/inputs';
import { showAlertMsg } from '../../util/messagesUtil';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { PagamentoPagSeguro } from './PagamentoPagSeguro';
import { recuperarBandeiraCartao, validateCard } from '../../util/cartaoUtil';
import { removerMinhaAgencia, salvarMinhaAgencia, getMinhaAgencia } from '../../util/applicationContext';
import { mascaraNumero } from '../../util/arraysUtil';
import ReactTooltip from 'react-tooltip';
import info from '../../estilo/comum/img/info_black_24dp.svg';
import safe from '../../estilo/comum/img/safe.svg';
import google from '../../estilo/comum/img/google.svg';
import { withRouter } from 'react-router-dom';


registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

export const validate = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Campo obrigatório'
    }
    if (!values.email) {
        errors.email = 'Campo obrigatório'
    }

    return errors
};

export class FormularioQueroMinhaAgencia extends React.Component {
    constructor(props) {
        super(props);
        this.fechar = this.fechar.bind(this);
        this.submit = this.submit.bind(this);

        this.state = {
            parcelas: [],
            tipoCampoChavePixSelecionado: null,
            tipoChavePixSelecionado: null,
            chaveCaptcha: null,
            tiposPlanos: null,
            bandeira: '',
            descricao: '',
            formaPagamento: 'CARTAO'
        };

        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        this.preencherComValoresStorage();
        this.recuperarTiposPlano();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cidade !== this.props.cidade) {
            this.props.changeFieldValue('formQueMinhaAgencia',
                'localidade', this.props.cidade);
        }
        if (!this.state.tiposPlanos) {
            this.recuperarTiposPlano();
        }
    }

    recuperarTiposPlano() {
        this.props.recuperarTiposPlano().then(resp => {
            if (resp) {
                this.setState({ tiposPlanos: resp.payload })
            }
        });
    }

    adicionarDescricaoTipoPlano(e) {
        if (e.value) {
            let texto = ''

            if (e.value === 'BASICO') {
                texto = <span className='textoDescricaoPlano'><b>LICENÇA ANUAL! GRÁTIS!</b>
                    Nesta opção, você terá sua Agência Virtual inteiramente GRÁTIS,
                    usando o domínio .online. Exemplo: https://brasilagencia.online</span>
            }
            if (e.value === 'SITE') {
                texto = <span className='textoDescricaoPlano'><b>VALOR PAGO POR ANO,
                    POR LICENÇA R$99,75</b> Nesta opção,
                    o domínio do seu site será: .com ou .com.br.
                    Exemplo: https://brasilagencia.com ou https://brasilagencia.com.br</span>
            }
            if (e.value === 'APLICATIVO_SITE') {
                texto = <span className='textoDescricaoPlano'><b>LICENÇA ANUAL!</b>&nbsp;<b>R$489,31</b>
                    Nesta opção, iremos desenvolver o seu aplicativo e publicá-lo na Google Play Store e na Apple Store.
                    O domínio do seu site será .com ou .com.br. Exemplo: https://brasilagencia.com ou https://brasilagencia.com.br</span>
            }
            this.setState({ descricao: texto })
        }
    }

    preencherComValoresStorage() {
        const comando = getMinhaAgencia();
        if (comando) {
            const agencia = comando.comandoCriacaoUsuario;
            this.props.changeFieldValue('formQueMinhaAgencia',
                'email', agencia.email);
            this.props.changeFieldValue('formQueMinhaAgencia',
                'cpf', agencia.cpf);
            this.props.changeFieldValue('formQueMinhaAgencia',
                'nome', agencia.nome);
            this.props.changeFieldValue('formQueMinhaAgencia',
                'telefoneUsuario', agencia.telefone);

            this.props.changeFieldValue('formQueMinhaAgencia',
                'nomeAgencia', agencia.pessoa.nomeAgencia);
            this.props.changeFieldValue('formQueMinhaAgencia',
                'rg', agencia.pessoa.rg);
            this.props.changeFieldValue('formQueMinhaAgencia',
                'urlDoSite', agencia.pessoa.urlDoSite);
            this.props.changeFieldValue('formQueMinhaAgencia',
                'dataNascimento', agencia.pessoa.dataNascimento);

        }
    }

    ehAmbienteTeste(numeroCartao) {
        return numeroCartao.length === 16
    }

    parserValor(valor) {
        return valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
        })
    }

    preencherESelecionarPrimeiraOpcaoPagamento(parcelasPagamento, numeroCartao) {
        const parcela = {
            label: parcelasPagamento[0].parcela,
            value: parcelasPagamento[0].qtdParcelas,
            valorTotal: parcelasPagamento[0].valorTotal,
            qtdParcelas: parcelasPagamento[0].qtdParcelas,
            metodoPagamento: parcelasPagamento[0].metodoPagamento,
        };
        this.props.changeFieldValue('formQueMinhaAgencia', 'parcelas', parcela);
        this.setState({ parcelas: parcelasPagamento, numeroCartao })

    }

    buscarParcelas(e) {
        const numeroCartao = e.target.value.replaceAll(' ', '');
        if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
            this.props.changeFieldValue('formQueMinhaAgencia', 'numeroCartaoCredito', numeroCartao);
            this.buscarInformacoesPagamentoPagSeguro(numeroCartao, this.props.tipoPlano.value)
        }

    }

    handleOpcaoPagamento(formaPagamento) {
        this.setState({ formaPagamento })
    }

    buscarInformacoesPagamentoPagSeguro(numeroCartao, tipoPlano) {
        let parcelasPagamento = [];
        this.setState({ parcelas: [] });
        this.props.changeFieldValue('formQueMinhaAgencia', 'numeroCartaoCredito', numeroCartao);
        if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
            const bandeiraCartao = recuperarBandeiraCartao(numeroCartao)
            this.setState({ bandeira: bandeiraCartao })
            this.props.recuperarOpcoesPagamento(tipoPlano, bandeiraCartao).then(resp => {
                if (resp) {
                    resp.payload.map(p => {
                        parcelasPagamento.push({
                            parcela: `${p.quantity} parcela(s) de ${this.parserValor(p.amount)} total de (${this.parserValor(p.totalAmount)}).`,
                            valorTotal: p.totalAmount,
                            qtdParcelas: p.quantity,
                            valorParcela: p.amount,
                            metodoPagamento: 'cartao',
                        })
                    })
                }
                this.preencherESelecionarPrimeiraOpcaoPagamento(parcelasPagamento, numeroCartao)
            }).catch(erro => {
                console.log(erro);
                showAlertMsg('Cartão inválido. Confira o numero do seu cartão de crédito!!')
            })
        }

    }

    limparDadosComprador() {
        this.props.changeFieldValue('formQueMinhaAgencia',
            'numeroCartaoCredito', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'dataValidadeCartaoCredito', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'nomeTitularCartaoCredito', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'dataNascimentoComprador', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'telefoneComprador', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'cepComprador', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'complementoEnderecoComprador', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'numeroEnderecoComprador', '');
        this.props.changeFieldValue('formQueMinhaAgencia',
            'parcelas', '');
    }


    onChangeTipoPlano(elemento) {
        if (this.ehPlanoPago(elemento.value) && this.state.numeroCartao) {
            this.buscarInformacoesPagamentoPagSeguro(this.state.numeroCartao, elemento.value)
        } else {
            this.limparDadosComprador()
        }
    }


    montarParametroUsuario(values) {
        console.log(values)
        const { formaPagamento } = this.state
        let comprador = null;
        if (values.tipoPlano && values.tipoPlano.value !== 'BASICO' && formaPagamento === 'CARTAO') {
            comprador = {
                cartao: {
                    numeroCartao: mascaraNumero(values.numeroCartaoCredito),
                    codigoSeguranca: values.codigoSegurancaCartaoCredito,
                    mesExpiracao: values.dataValidadeCartaoCredito.substr(0, 2),
                    anoExpiracao: '20'.concat(values.dataValidadeCartaoCredito.substr(3, 4)),
                    bandeira: this.state.bandeira
                },
                cpfComprador: values.cpfComprador,
                nomeComprador: values.nomeTitularCartaoCredito,
                email: values.email,
                telefoneComprador: values.telefoneComprador,
                sanderHash: '',
                dataNascimentoComprador: values.dataNascimentoComprador,
                endereco: {
                    id: 0,
                    cep: values.cepComprador,
                    complemento: values.complementoEnderecoComprador,
                    numero: values.numeroEnderecoComprador,
                },
            };

        }
        const comandoCriacaoUsuario = {
            id: '',
            nome: values.nome,
            email: values.email,
            roles: [],
            telefone: values.telefoneUsuario,
            cpf: values.cpf,
            plataforma: 'adminagencia',
            pessoa: {
                id: '',
                rg: values.rg ? values.rg : null,                
                dataNascimento: values.dataNascimento ? values.dataNascimento : null,
                tipoPlano: values.tipoPlano ? values.tipoPlano.value : null,
                nomeAgencia: values.nomeAgencia ? values.nomeAgencia : null,
            },
            links: [{ url: this.ehPlanoPago(values.tipoPlano.value) ? values.urlDoSite + '.com.br' : values.urlDoSite + '.online' }],
        };

        return {
            comandoCriacaoUsuario: comandoCriacaoUsuario,
            dadosComprador: comprador,
            quantidadeParcelas: values.parcelas ? values.parcelas.qtdParcelas : 0,
            plano: this.props.tipoPlano.value,
        };

    }

    submit(values) {
        const { formaPagamento } = this.state;
        const url = `/recibo-pagamento?plano=${values.tipoPlano.value}&formaPagamento=${formaPagamento}`;
        const minhaAgencia = this.montarParametroUsuario(values, formaPagamento);
        salvarMinhaAgencia(minhaAgencia);
        if (formaPagamento === 'PIX') {
            this.props.cadastrarUsuarioPagamentoPix(minhaAgencia)
                .then(() => {
                    this.fechar();
                    removerMinhaAgencia()
                    this.props.history.push(url);

                })
        } else {
            this.props.cadastrarUsuario(minhaAgencia)
                .then(() => {
                    this.fechar();
                    removerMinhaAgencia()
                    this.props.history.push(url);
                })
        }
    }

    fechar() {
        this.props.reset('formQueMinhaAgencia');
    }

    ehPlanoPago(value) {
        if (value) {
            return value !== 'BASICO'
        }
        return this.props.tipoPlano && this.props.tipoPlano.value !== 'BASICO'
    }

    changeRecaptcha(value) {
        if (value) {
            this.setState({ chaveCaptcha: value })
        }
    }

    render() {
        const {
            handleSubmit, submitting, invalid,
        } = this.props;
        const { valorTotalGeral, parcelas, tiposPlanos } = this.state;
        if (!tiposPlanos) {
            return ''
        }
        return (
            <div className='container'>
                <div>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5>Minha Agência</h5>
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={handleSubmit(this.submit)}>
                                <div className="form-row mt-2">
                                    <div className="col-12 col-sm-12 col-md-5">
                                        <Field name='tipoPlano'
                                            options={tiposPlanos}
                                            component={renderFieldSelect}
                                            validate={[requiredSelect]}
                                            label='Tipo Plano'
                                            onChange={e => {
                                                this.onChangeTipoPlano(e),
                                                    this.adicionarDescricaoTipoPlano(e)
                                            }}
                                            placeholder='Selecione...'
                                        />
                                    </div>
                                </div>
                                <div className='form-row mt-2' id='cardTextoDescricao'>
                                    <div className='col-12 col-sm-4 col-md-4'>
                                        {this.state.descricao}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <Field name="email" type="email" component={renderFieldEmail} label="E-mail:"
                                            validate={[required]}
                                            css="form-control" readOnly={false} />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <Field name="cpf" type="text" component={renderFieldCpf} label="CPF:"
                                            validate={[requiredCPF]}
                                            css="form-control" />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <Field name="rg" type="text" max={15} component={renderField} label="RG:"
                                            validate={[required]}
                                            css="form-control" />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-12 col-sm-5 col-md-5">
                                        <Field name="nome" type="text" validate={[required, apenasLetra]} component={renderFieldText}
                                            label="Nome Completo:"
                                            css="form-control" />
                                    </div>
                                    <div className="col-12 col-sm-3 col-md-3">
                                        <Field name="telefoneUsuario" type="text"
                                            component={renderFieldTelefone}
                                            validate={[required]}
                                            css="form-control" label="Telefone" />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <Field name="dataNascimento" type="text"
                                            component={renderFieldDataNascimento}
                                            label='Data de nascimento'
                                            validate={[required]}
                                            css="form-control" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <span className="align-self-center tooltip-info">
                                            <div className="tooltip-ut">
                                                <ReactTooltip className="tooltip-ut" id='infoUrl'
                                                    type="dark" effect="float">
                                                    <div className="row">Preencha com a URL que deseja para o seu site, </div>
                                                    <div className="row">ela estará sujeita a disponibilidade.</div>
                                                </ReactTooltip>
                                            </div>
                                            <a data-tip data-for='infoUrl' id='imgInfoUrl'>
                                                <img className="material-icons" src={info} />
                                            </a>
                                        </span>
                                        <ReactTooltip className="tooltip-ut" id='infoNome'
                                            type="dark" effect="float">
                                            <div className="row">Esse nome será sua identificação no nosso sistema</div>
                                        </ReactTooltip>
                                        <a data-tip data-for='infoNome' id='imgInfo'>
                                            <img className="material-icons" src={info} />
                                        </a>
                                        <Field name="nomeAgencia" type="text" component={renderField} id="nomeAgencia"
                                            label="Nome agência:"
                                            css="form-control" readOnly={this.props.tipoPlano ? false : true}
                                            validate={[required]} />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <div>
                                            <div className='input-group mb-3'>
                                                <Field name='urlDoSite' id='urlDoSite' type='text' component={renderField}
                                                    label='Qual URL deseja?'
                                                    css='form-control' readOnly={this.props.tipoPlano ? false : true}
                                                    validate={[required]} />
                                                {this.props.tipoPlano ?
                                                    <div className='input-group-append'>
                                                        <span className='input-group-text' id='basic-addon2'>
                                                            {this.ehPlanoPago(this.props.tipoPlano ? this.props.tipoPlano.value : '') ? '.com.br' : '.online'}</span>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {this.ehPlanoPago() ?
                                        <PagamentoPagSeguro parcelas={parcelas}
                                            handleOpcaoPagamento={this.handleOpcaoPagamento.bind(this)}
                                            formaPagamento={this.state.formaPagamento}
                                            valorTotalGeral={valorTotalGeral}
                                            buscarParcelas={this.buscarParcelas.bind(this)} /> : ''
                                    }

                                </div>
                                <div className='row justify-content-md-center modal-footer captcha'>
                                    <div className='col col-lg-3 ssl'>
                                        <img id='ssl' src={safe} />
                                    </div>
                                    <div className="col-md-auto">
                                        <ReCaptchaV2
                                            ref={this.recaptchaRef}
                                            style={{ display: 'inline-block', justifyContent: 'center' }}
                                            sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                                            onChange={(value) => this.changeRecaptcha(value)}
                                            onExpired={() => this.setState({ chaveCaptcha: null })}
                                            onErrored={() => this.setState({ chaveCaptcha: null })} />
                                    </div>
                                    <div className='col col-lg-3 google'>
                                        <img id='google' src={google} />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <button id="btn-salvar" type="submit" disabled={invalid || submitting || !this.state.chaveCaptcha}
                                        className="btn-laranja finaliza">FINALIZAR CADASTRO
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        dataNascimento: formValueSelector('formQueMinhaAgencia')(state, 'dataNascimento'),
        cidadeForm: formValueSelector('formQueMinhaAgencia')(state, 'cidadeForm'),
        tipoPlano: formValueSelector('formQueMinhaAgencia')(state, 'tipoPlano'),
    }
}


export const mapDispatchToProps = dispatch => (
    bindActionCreators({
        changeFieldValue,
        cadastrarUsuario,
        cadastrarUsuarioPagamentoPix,
        recuperarOpcoesPagamento,
        recuperarTiposPlano
    }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'formQueMinhaAgencia'
})(FormularioQueroMinhaAgencia)));
