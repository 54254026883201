export const CADASTAR_USUARIO_PIX = '@minhaAgenciaReducer/CADASTAR_USUARIO_PIX';


const INITIAL_STATE = {
  pedido: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CADASTAR_USUARIO_PIX:
      return {
        ...state,
        pedido: action.payload.data
      };
    default:
      return state;
  }
};
