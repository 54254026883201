import axios from 'axios';
import _ from 'lodash';

import store from '../store/configureStore';
import {
  RESPONSE_ERROR_HANDLER_POSTPONE_HEADER,
  arrayBufferToJson,
  extractError,
  isArrayBuffer,
  errorHasDomainViolations
} from '../util/responseUtil';
import { cleanResponseDomainViolations, handleResponseDomainViolations } from '../actions/responseErrorHandlerActions';
import { showErrorMsg } from '../util/messagesUtil';
import { getBrowserHistory } from '../util/applicationContext';
import {removerClassModalOpen} from '../util/modalUtil';

export const ACCESS_DENIED = 401;
export const FORBBIDEN = 403;
export const FAILED_DEPENDENCY = 424;
export const PRECONDITION_FAILED = 412;

const errorHandlers = {
  [ACCESS_DENIED]: {
    handleError: () => {
      const ehVenda = getBrowserHistory().location.search === '?venda'
      if(ehVenda){
        getBrowserHistory().push('/login?venda');
      }else{
        getBrowserHistory().push('/login');
      }
      return 'Usuário não autenticado. Informe usuário e senha corretos e tente novamente.';
    }
  },
  [FORBBIDEN]: {
    handleError: () => {
      getBrowserHistory().push('/forbidden');
      return 'Você não tem permissão para acessar este recurso, solicite ao responsável, por favor.';
    }
  },
  [PRECONDITION_FAILED]: {
    handleError: (response, error) => {
      if (errorHasDomainViolations(error)) {
        if (response.config.headers[RESPONSE_ERROR_HANDLER_POSTPONE_HEADER]) {
          store.dispatch(handleResponseDomainViolations(error));
        } else {
          return _.join(_.map(error.violacoes, violacao => violacao.mensagem), ', ');
        }
      } else {
        store.dispatch(cleanResponseDomainViolations());
        getBrowserHistory().push('/erro');
        return response.statusText;
      }
    }
  },
  [FAILED_DEPENDENCY]: {
    handleError: (response) => {
      getBrowserHistory().push('/erro');
      const responseAsJSON = isArrayBuffer(response.data) ? arrayBufferToJson(response.data) : response.data;
      const servico = responseAsJSON && _.size(responseAsJSON.servicoInformado) ? responseAsJSON.servicoInformado : null;
      return servico ? `O serviço "${servico}" está indisponível no momento.` : response.statusText;
    }
  },
  default: {
    handleError: (response) => {
      getBrowserHistory().push('/erro');
      return response.statusText;
    }
  }
};

export const errorInterceptorOnFulfilledResponse = (response) => {
  if (response.config.headers[RESPONSE_ERROR_HANDLER_POSTPONE_HEADER]) {
    store.dispatch(cleanResponseDomainViolations());
  }
  return response;
};

export const errorInterceptorOnRejectedResponse = (err) => {
  removerClassModalOpen();
  let msgText = '';
  if (_.isUndefined(err.response)) {
    msgText = err.message;
    // console.log(msgText)
    // console.log(err.stack)
    errorHandlers.default.handleError(msgText);
  } else {
    const { code, json } = extractError(err);
    const errorHandlerSelecionado = code >= 400 ? errorHandlers.default : null;
    const errorHandler = !_.isUndefined(errorHandlers[code]) ? errorHandlers[code]
      : errorHandlerSelecionado;
    if (errorHandler) {
      msgText = errorHandler.handleError(err.response, json);
    } else {
      const statusText = err.response;
      msgText = `${code} ${statusText}`;
    }
  }
  if (_.size(msgText)) showErrorMsg(msgText);
  return Promise.reject(err);
};

axios.interceptors.response.use(errorInterceptorOnFulfilledResponse, errorInterceptorOnRejectedResponse);
