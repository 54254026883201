import React, {Component} from 'react'
import imagemPrincipal from '../../estilo/comum/img/bg_queroumagenciaslide-familia.png'
import sistemaVexado from '../../estilo/comum/img/sistema_vexado_queroumaagencia-300x171 (1).png'
import queroUmaAgencia from '../../estilo/comum/img/queroumaagencia.png'
import pcAgencia from '../../estilo/comum/img/bg_pc_quero-uma-agencia-1.png'
import dinheiro from '../../estilo/comum/img/dinheiro_queroumaagencia.png'
import fundoDinheiro from '../../estilo/comum/img/fundo-dinheiro.png'
import whats from '../../estilo/comum/img/whatsapp.png'
import insta from '../../estilo/comum/img/insta.webp'
import google from '../../estilo/comum/img/google.png'
import pessoa from '../../estilo/comum/img/pessoa.png'
import face from '../../estilo/comum/img/face.png'
import homem from '../../estilo/comum/img/homem.png'
import {Link} from 'react-router-dom'

export default class PaginaPrincipal extends Component {

    arrow() {
        return <svg xmlns='http://www.w3.org/2000/svg' id='arrow' width='12' height='12' fill='currentColor'
                    className='bi bi-caret-up-fill' viewBox='0 0 16 16'>
            <path
                d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/>
        </svg>
    }

    buttonEmpresas() {
        return (
            <a href='https://vexado.com/pesquisar-empresas' target="_blank" rel="noopener noreferrer">
                <button type='button' className='btn btn-site-pronto'>Conheça as Empresas</button>
            </a>
        )
    }

    buttonVejaUmSitePronto() {
        return (
            <a href='https://vexado.com.br/pesquisar-empresas' target="_blank" rel="noopener noreferrer">
                <button type='button' className='btn btn-site-pronto'>Clique aqui e veja um site pronto</button>
            </a>
        )
    }

    buttonQueroMinhaAgencia() {
        return <a href='/quero-minha-agencia-gratis'>
            <button type='button' className='btn btn-success'>EU QUERO MEU SITE GRÁTIS</button>
        </a>
    }

    render() {
        return (
            <div>
                <div id='principal' className='bg-primary' style={{height: '100%'}}>
                    <div className='row'>
                        <div id='proprio' className='col-md-6'>
                            <h1>
                                Já pensou em ter o seu PRÓPRIO site de vendas de passagens? <br/>
                                E o seu PRÓPRIO APLICATIVO?
                            </h1>
                            {this.buttonQueroMinhaAgencia()}
                            <h5>
                                Isso mesmo! Site com domínio .online, inteiramente GRÁTIS!
                            </h5>
                            <h5>
                                Pensa aí, vender qualquer passagem <br/>
                                das empresas cadastradas no sistema, recebendo as mesmas comissões
                                praticadas... Maravilha, não é?!
                            </h5>
                        </div>
                        <div id='img-principal' className='col-md-6'>
                            <div id=''>
                                <img id='imgPrincipal' src={imagemPrincipal}
                                     style={{width: '615px', float: 'right'}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-4'>
                    <section className='news'>
                        <div className='row'>
                            <div className='col-md-8 site'>
                                <h1>
                                    O seu site poderá VENDER passagens de TODAS as EMPRESAS cadastradas no SISTEMA
                                    VEXADO.
                                </h1>
                            </div>
                            <div>
                                <img id='imgOnibus' src={sistemaVexado} style={{width: '300px'}}/>
                            </div>
                        </div>
                    </section>
                </div>
                <hr/>
                <div className='mt-2 mb-2'>
                    <section id='sessionEmpParceiras'>
                        <div id='empresasParceiras' className='row'>
                            <div className='col-sm-12 col-md-6 celu' style={{textAlign: 'center'}}>
                                <a href='https://vexado.com/pesquisar-empresas' target="_blank"
                                   rel="noopener noreferrer">
                                    <img id='celular'
                                         src='https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/Empresas_parceiras_Sistema_Vexado-_sistema_de_passagem-_sistema_de_gest%C3%A3o_de_empresa_de_Onibus.webp'
                                         style={{width: '452px'}}/>
                                </a>
                            </div>
                            <div id='empParceirasText' style={{textAlign: 'justify'}} className='col-sm-12 col-md-6'>
                                <h2 className='empresas-parceiras'>EMPRESAS PARCEIRAS</h2>
                                <div className='dominio'>
                                    <h2 style={{paddingBottom: '20px'}}>&quot;Junte-se a nós para ter acesso exclusivo a
                                        uma ampla variedade de
                                        passagens de ônibus de empresas em todo o Brasil.</h2>
                                    <h2 style={{paddingBottom: '20px'}}>Conte com o suporte da nossa rede para alavancar
                                        seus resultados e comece a
                                        vender hoje mesmo!
                                        Conheça todas as empresas disponíveis agora!&quot;</h2>
                                </div>
                                <div className="top2">
                                    {this.buttonEmpresas()}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='divulgar'>
                    <div className='row'>
                        <div id='imgCelular' className='col-md-6 celu'>
                            <img id='celular' src={queroUmaAgencia} style={{width: '452px'}}/>
                        </div>
                        <div id='imgCelularTxt' className='col-md-6'>
                            <h2>
                                Como seria se seus CLIENTES nem precisassem sair de casa para GERAR RENDA para VOCÊ?
                            </h2>
                            <h4>
                                Pode ficar tranquilo, pois a <strong>GESTÃO <br/>FINANCEIRA OPERACIONAL e
                                RELACIONAL</strong> ficará tudo por nossa conta!
                            </h4>
                            <h4>
                                O <strong>PAGAMENTO</strong> de sua <strong>COMISSÃO</strong>,
                                a <strong>OPERAÇÃO</strong> de <strong>CARTÃO</strong>,
                                os <strong>REPASSES</strong> para <strong>EMPRESAS</strong> e o <strong>PAGAMENTO DO
                                IMPOSTO</strong>, nós iremos fazer tudo!
                            </h4>
                            <h3>
                                PARA VOCÊ, É SÓ DIVULGAR!
                            </h3>
                            {this.buttonQueroMinhaAgencia()}
                        </div>
                    </div>
                </div>
                <div className='afiliado'>
                    <h1>
                        ISSO NÃO É LINK DE AFILIADO!
                    </h1>
                </div>
                <hr/>
                <div className='container dom'>
                    <div className='row'>
                        <div className='col-md-5 dominio'>
                            <h2>
                                Com a sua “Agência Virtual” você terá o seu próprio DOMÍNIO, o seu próprio APLICATIVO, o
                                seu próprio CLIENTE!
                            </h2>
                            <h4>
                                <strong>Não corra riscos com reservas feitas por WhatsApp! Evite problemas e
                                    transtornos!</strong>
                            </h4>
                            <h2 className='agencia-virtual'>
                                Com a sua <strong>Agência Virtual</strong>, o seu <strong>CLIENTE</strong> entra no
                                seu <strong>SITE, COMPRA</strong> a passagem e viaja!
                            </h2>
                            <div className="top2">
                                {this.buttonVejaUmSitePronto()}
                                {this.buttonQueroMinhaAgencia()}
                            </div>
                        </div>

                        <div id='ImgComputador' className='col-md-7 pc'>
                            <img id='computador' src={pcAgencia} style={{width: '800px', left: '-150px'}}/>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className='dinheiro'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img id='fundoIdoso' src={fundoDinheiro} style={{width: '470px', position: 'relative'}}/>
                            <div>
                                <img id='idoso' src={dinheiro}
                                     style={{width: '740px', position: 'absolute', top: '0'}}/>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h1>
                                Feito para QUALQUER pessoa COMUM, que queira realmente buscar RENDA EXTRA, para
                                incrementar o orçamento.
                            </h1>
                            <h1>
                                É SÓ TER DISPOSIÇÃO DE DIVULGAR A SUA AGÊNCIA VIRTUAL NAS REDES SOCIAIS!
                            </h1>
                            <div className='container redes'>
                                <img src={whats} style={{width: '100px'}}/>
                                <img src={insta} style={{width: '100px'}}/>
                                <img src={face} style={{width: '100px'}}/>
                                <img src={google} style={{width: '100px'}}/>
                                <img src={pessoa} style={{width: '100px'}}/>
                            </div>
                            {this.buttonQueroMinhaAgencia()}
                        </div>
                    </div>
                </div>
                <div className='revolucao'>
                    <div className='row'>
                        <div className='surpreso'>
                            <img id='imgHomem' src={homem} style={{width: '400px'}}/>
                        </div>
                        <div id='quero' className='col-md-4'>
                            <h2>
                                Veio até aqui e ainda não clicou em
                            </h2>
                            {this.buttonQueroMinhaAgencia()}
                            <h2 id='anda'>
                                Anda logo, cadastre-se e venha fazer parte VOCÊ também dessa revolução DIGITAL!
                            </h2>
                        </div>
                        <div id='lote' className='col-md-4'>
                            <h3>
                                Este primeiro lote está com vagas limitadas para as criações de sites GRATUITOS! Vamos
                                limitar devido a grande quantidade de procura!
                            </h3>
                            {this.buttonVejaUmSitePronto()}
                        </div>
                    </div>
                </div>
                <div className='container estatistica'>
                    <div className='row numeros cartao'>
                        <div className='card' style={{width: '16rem', margin: '0 15px 0 0'}}>
                            <div className='card-body'>
                                <span>176</span>
                                <div>EMPRESAS JÁ <br/> CADASTRADAS</div>
                            </div>
                        </div>
                        <div className='card' style={{width: '16rem', margin: '0 15px 0 0'}}>
                            <div className='card-body'>
                                <span>250</span>
                                <div>ITINERÁRIOS DISPONÍVEIS <br/> DIARIAMENTE</div>
                            </div>
                        </div>
                        <div className='card' style={{width: '16rem', margin: '0 15px 0 0'}}>
                            <div className='card-body'>
                                <span>1500</span>
                                <div>MÉDIA DIARIA DE <br/> TRECHOS DISPONÍVEIS</div>
                            </div>
                        </div>
                        <div className='card' style={{width: '16rem', margin: '0 15px 0 0'}}>
                            <div className='card-body'>
                                <span>132</span>
                                <div style={{padding: '20px'}}>SITES CRIADOS</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='planos'>
                    <h1>
                        Planos:
                    </h1>
                </div>
                <div className='container-geral-planos'>
                    <div className='container-flex-planos'>
                        <div className='card' style={{width: '24rem', margin: '0 30px 0 0'}}>
                            <div className='card-body plano plano-1' style={{background: '#E7E7E7'}}>
                                <h4>PLANO ONLINE</h4> <br/> <h5>LICENÇA ANUAL!</h5>
                                <h4 style={{color: '#000000', textDecoration: 'line-through', fontSize: '21px'}}>R$
                                    99,75</h4>
                                <h5 style={{color: '#000000'}}>Oferta temporária!</h5>
                                <h1>GRÁTIS</h1>
                                <span>Nesta opção, você terá sua Agência Virtual inteiramente GRÁTIS, usando o domínio .vexado.com. Exemplo: https://brasilagencia.vexado.com</span>
                                {/*<Link to="/quero-minha-agencia-gratis">*/}
                                {/*    <button type='button' className='btn btn-outline-light cadastrar'>CADASTRAR*/}
                                {/*        AGORA {'>'}</button>*/}
                                {/*</Link>*/}
                                <button type='button' className='btn btn-outline-light cadastrar'
                                        style={{color: 'red'}}>
                                    ESGOTADO
                                </button>
                            </div>
                        </div>
                        <div className='card' style={{width: '24rem'}}>
                            <div>
                                <div>
                                    <div className='card-body container-descricao-plano' style={{
                                        border: '1px solid rgb(231, 231, 231)'
                                    }}>
                                        <div className='cad'>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>PLANO ONLINE</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>COMISSÃO SOBRE VENDA: 3%</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>URL PODE SER <span style={{
                                                    fontSize: '14px',
                                                    color: '#000000',
                                                    textTransform: 'lowercase'
                                                }}>https://brasilagencia.vexado.com</span></h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>SITE 100% OTIMIZADO EM SEO</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>TREINAMENTO GRÁTIS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>TEMPLATES PRONTOS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>PAINEL DE GESTÃO</h5>
                                            </div>
                                            <div>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-flex-planos'>
                        <div className='card' style={{width: '24rem', margin: '0 30px 10px 0', background: '#0064E8'}}>
                            <div className='card-body planoAnual plano-2'>
                                <h4>PLANO PLUS</h4> <br/> <h5>SITE <br/> VALOR PAGO POR ANO, <br/> POR LICENÇA!</h5>
                                <h4 style={{color: '#FFFFFF', textDecoration: 'line-through', fontSize: '21px'}}>R$
                                    199,49</h4>
                                <h5 style={{color: '#FFFFFF'}}>Oferta temporária!</h5>
                                <h1>R$ 99,75</h1>
                                <span>Nesta opção, o domínio do seu site será .com ou .com.br. Exemplo: https://brasilagencia.com | https://brasilagencia.com.br</span>
                                <Link to="/quero-minha-agencia-gratis">
                                    <button type='button' className='btn btn-outline-warning cadastrar'>CADASTRAR
                                        AGORA {'>'}</button>
                                </Link>
                            </div>
                        </div>
                        <div className='card' style={{width: '24rem'}}>
                            <div>
                                <div>
                                    <div className='card-body container-descricao-plano' style={{
                                        border: '1px solid rgb(0, 100, 232)'
                                    }}>
                                        <div className='cad'>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>PLANO PLUS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>COMISSÃO SOBRE VENDA: 4,5%</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>URL PODE SER .VEXADO.COM OU .COM</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>SITE 100% OTIMIZADO EM SEO</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>TREINAMENTO GRÁTIS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>TEMPLATES PRONTOS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>PAINEL DE GESTÃO</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>&nbsp;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-flex-planos'>
                        <div className='card anual' style={{width: '24rem', background: '#FFAD00'}}>
                            <div className='card-body plano plano-3'>
                                <h4>PLANO VIP</h4> <h5><br/> APLICATIVO E SITE <br/> LICENÇA ANUAL!</h5>
                                <h4 style={{color: '#101B8B', textDecoration: 'line-through', fontSize: '21px'}}>R$
                                    1.299,49</h4>
                                <h5 style={{color: '#101B8B'}}>Oferta temporária!</h5>
                                <h1>R$ 489,31</h1>
                                <span>Nesta opção, iremos desenvolver o seu aplicativo e publicá-lo na Google Play Store e na Apple Store. O domínio do seu site será .com ou .com.br. Exemplo: https://brasilagencia.com | https://brasilagencia.com.br</span>
                                <Link to="/quero-minha-agencia-gratis">
                                    <button type='button' className='btn btn-outline-warning cadastrar'>CADASTRAR
                                        AGORA {'>'}</button>
                                </Link>
                            </div>
                        </div>
                        <div className='card' style={{width: '24rem'}}>
                            <div className='p-vip'>
                                <div>
                                    <div className='card-body container-descricao-plano' style={{
                                        border: '1px solid #FFBA00'
                                    }}>
                                        <div className='cad'>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>PLANO VIP</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>COMISSÃO SOBRE VENDA: 5%</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>URL PODE SER .VEXADO.COM OU .COM</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>SITE 100% OTIMIZADO EM SEO</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>TREINAMENTO GRÁTIS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>TEMPLATES PRONTOS</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>PAINEL DE GESTÃO</h5>
                                            </div>
                                            <div className='cad-body descricao-plano'>
                                                <span></span>
                                                <h5 style={{color: '#000000'}}>APLICATIVO PUBLICADO NA PLAY STORE</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='titulo-pergunta'>
                    <p>
                        Perguntas e respostas!
                    </p>
                </div>
                <div className='container'>
                    <div className='accordion' id='accordionExample'>
                        <div className='card'>
                            <div className='card-header' id='headingOne'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseOne' aria-expanded='true'
                                            aria-controls='collapseOne'>
                                        {this.arrow()} O que é a “Agência Virtual”?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseOne' className='collapse' aria-labelledby='headingOne'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Nada mais é do que seu guichê de vendas disponível na internet com a possibilidade
                                    de vender passagens de todas as empresas que trabalham com o Vexado Soluções
                                    Tecnológicas ®. Com isso, VOCÊ, dono da Agência Virtual, poderá expandir e dobrar,
                                    triplicar ou quadriplicar o seu número de vendas.
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header' id='headingTwo'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseTwo' aria-expanded='false'
                                            aria-controls='collapseTwo'>
                                        {this.arrow()} Quem pode adquirir a “Agência Virtual”?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseTwo' className='collapse' aria-labelledby='headingTwo'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Qualquer pessoa com idade igual ou superior a 18 anos, que queira mudar de vida e
                                    obter uma renda extra, vendendo passagem de ônibus de forma digital. E para você que
                                    já faz parte do uso do sistema de transporte.

                                    Exemplo: gerentes; equipe de suporte; bilheteiros; agenciadores; motoristas.
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header' id='headingThree'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseThree' aria-expanded='false'
                                            aria-controls='collapseThree'>
                                        {this.arrow()} Com a Agência Virtual, como eu posso aumentar meu número de
                                        vendas de forma exponencial?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseThree' className='collapse' aria-labelledby='headingThree'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Iremos disponibilizar ferramentas para o Marketing de sua Agência Virtual. Você,
                                    vendedor ou responsável pela agência, deverá fazer os cursos de como utilizar essas
                                    ferramentas para a aplicação destas nas plataformas digitas para maior captação de
                                    clientes para o seu site.
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header' id='headingFor'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseFor' aria-expanded='false'
                                            aria-controls='collapseFor'>
                                        {this.arrow()} Além dessas vantagens com a agência digital, terei outras
                                        vantagens?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseFor' className='collapse' aria-labelledby='headingFor'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Sim. Além de ter acesso aos recursos de Marketing, você também terá ferramentas de
                                    automação de mensagens, como o WhatsApp Responder, que opera na tratativa de
                                    clientes e motoristas. Para mais detalhes, somente adquirindo a Agência Virtual.
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header' id='headingFive'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseFive' aria-expanded='false'
                                            aria-controls='collapseFive'>
                                        {this.arrow()} Como posso adquirir a minha “Agência Virtual”?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseFive' className='collapse' aria-labelledby='headingFive'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Simples e fácil! Clique no botão que irá lhe direcionar ao cadastro. Escolha o seu
                                    plano conforme as 3 opções discriminadas nesta página e aguarde as análises
                                    comerciais. Os usuários do Sistema Vexado, do Vexado Soluções Tecnológicas ®, terão
                                    preferência no pacote GRATUITO referente ao domínio .online. <a id='adquirirAgencia'
                                                                                                    href='/formulario-de-cadastro/'>Não
                                    perca tempo! Se cadastre e não fique de fora dessa!</a>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header' id='headingSix'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseSix' aria-expanded='false'
                                            aria-controls='collapseSix'>
                                        {this.arrow()} Por quanto tempo terei acesso a minha “Agência Virtual”?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseSix' className='collapse' aria-labelledby='headingSix'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Todos os pacotes, possuem o período padrão de 365 dias para as suas licenças. Os
                                    valores podem sofrer variações para renovações. Leia as condições de adesão e
                                    renovação para mais informações.
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header' id='headingSeven'>
                                <h2 className='mb-0'>
                                    <button className='btn btn-link btn-block text-left collapsed' type='button'
                                            data-toggle='collapse' data-target='#collapseSeven' aria-expanded='false'
                                            aria-controls='collapseSeven'>
                                        {this.arrow()} Como e por onde irei obter acesso a estes recursos?
                                    </button>
                                </h2>
                            </div>
                            <div id='collapseSeven' className='collapse' aria-labelledby='headingSeven'
                                 data-parent='#accordionExample'>
                                <div className='card-body'>
                                    Logo após a efetivação dos dados enviados por você, iremos enviar para o seu e-mail
                                    e WhatsApp, as informações de acesso ao seu Portal de Gestão, com todo o conteúdo e
                                    aplicações necessárias, para a boa gestão e divulgação da sua Agência Virtual, tudo
                                    de forma ONLINE.
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

