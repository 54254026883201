import store from '../store/configureStore'
import {fecharLoading, mostrarLaoding} from '../actions/loadingActions'


export const SLOW_REQUEST_HEADER = 'X-Slow-Request'

export const SLOW_REQUEST_CONFIG = {
    headers: {
        [SLOW_REQUEST_HEADER]: true,
    },
}

export const iniciarLoading = () => {
    store.dispatch(mostrarLaoding())
}

export const finalizarLoading = () => {
    store.dispatch(fecharLoading())
}
