// eslint-disable-next-line no-unused-vars
export const validateCard = value => {
  const novoValor = value.replace(/\D/g, '');
  let sum = 0;
  let shouldDouble = false;
  for (let i = novoValor.length - 1; i >= 0; i--) {
    let digit = parseInt(novoValor.charAt(i));
    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  
  const valid = (sum % 10) === 0;
  let accepted
  
  // loop through the keys (visa, mastercard, amex, etc.)
  Object.keys(acceptedCreditCards).forEach((key) => {
    if (acceptedCreditCards[key].test(novoValor)) {
      accepted = true;
      return;
    }
  });
  
  return valid && accepted;
}

export const validateCVV = (creditCard, cvv) =>{
  // remove all non digit characters
  creditCard.replace(/\D/g, '');
  cvv.replace(/\D/g, '');
  // american express and cvv is 4 digits
  if ((acceptedCreditCards.amex).test(creditCard)) {
    if((/^\d{4}$/).test(cvv))
      return true;
  } else if ((/^\d{3}$/).test(cvv)) { // other card & cvv is 3 digits
    return true;
  }
  return false;
}

const acceptedCreditCards = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  amex: /^3[47][0-9]{13}$/,
  discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
};

export const recuperarBandeiraCartao = (numero) => {
  for (var cartao in acceptedCreditCards)
    if (numero.match(acceptedCreditCards[cartao]))
      return cartao;
  return '';
}